<template>
  <md-list-item>
    <md-avatar :style="tag.attr" class="md-avatar-icon"><md-icon>label</md-icon></md-avatar>
    <span>{{ tag.name }}</span>
    <span>
      <md-menu md-size="4" ref="addTagMenu">
        <md-button class="md-icon-button" md-menu-trigger><md-icon>edit</md-icon></md-button>
        <md-menu-content>
          <div class="md-menu-container">
            <tag-editor @submit="editTag" :tag="{ tagName: tag.name, tagColor: tag.color }" />
          </div>
        </md-menu-content>
      </md-menu>
      <md-button class="md-icon-button" v-on:click="deleteTag(tag)"><md-icon>delete</md-icon></md-button>
    </span>
  </md-list-item>
</template>

<script>
import TagEditor from '../TagEditor.vue';
import Hermes    from 'plugins/Hermes';
import bus       from 'src/Components/bus';
import { mapActions } from 'vuex';

export default {
  props : {
    tag : {
      type     : Object,
      required : true
    }
  },
  components : {
    TagEditor
  },
  data () {
    return {

    };
  },
  methods : {
    ...mapActions({
      toastInfo: 'toastInfo',
      toast: 'toast',
      toastError: 'toastError'
    }),
    editTag (tag) {
      if (!tag) {
        return this.$refs.addTagMenu.close();
      }
      var data = {
        name  : tag.tagName,
        color : tag.tagColor.hex
      };
      Hermes.run({
        route  : 'tag.updateTag',
        params : {
          id : this.tag.id
        },
        data : data
      }, () => {
        bus.$emit('updateTagsList');
        this.$store.dispatch('getTemplateListByCateg', {});
        this.$store.dispatch('getTemplateListWithCateg', {});
        this.$refs.addTagMenu.close();
      }, (data) => {
        this.toastError(data.message);
      });
    },
    deleteTag : function (tag) {
      Hermes.run({
        route  : 'tag.deleteTag',
        params : {
          id : tag.id
        }
      }, () => {
        bus.$emit('updateTagsList');
        this.$store.dispatch('getTemplateListByCateg', {});
        this.$store.dispatch('getTemplateListWithCateg', {});
      }, (data) => {
        this.toastError(data.message);
      });
    }
  }
};
</script>