import Hermes from 'plugins/Hermes';

export default {
  // It return a list of report with category
  getTemplateListWithCateg (cb) {
    Hermes.run({
      route : 'report.getReports'
    }, (res) => {
      // Color for category
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].category.attr = 'background-color: #' + res.data[i].category.color + ';';
        // Color for Tag
        for (var j = 0; j < res.data[i].tags.length; j++) {
          res.data[i].tags[j].attr = 'background-color: #' + res.data[i].tags[j].color + ';';
        }
      }
      cb(res.data);
    });
  },
  // It return a list of Categories with their reports
  getTemplateListByCateg (cb) {
    Hermes.run({
      route : 'report.getReportsByCateg'
    }, (res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].attr = 'color: #' + res.data[i].color + ';';
        for (var j = 0; j < res.data[i].reports.length; j++) {
          for (var k = 0; k < res.data[i].reports[j].tags.length; k++) {
            res.data[i].reports[j].tags[k].attr = 'background-color: #' + res.data[i].reports[j].tags[k].color + ';';
          }
        }
      }
      cb(res.data);
    });
  },
  // It return a list of reports which has no category
  getTemplateListWithoutCateg (cb) {
    Hermes.run({
      route : 'report.getReportsWithoutCategory'
    }, (res) => {
      for (var i = 0; i < res.data.length; i++) {
        // Color for Tag
        for (var j = 0; j < res.data[i].tags.length; j++) {
          res.data[i].tags[j].attr = 'background-color: #' + res.data[i].tags[j].color + ';';
        }
      }
      cb(res.data);
    });
  }
};