<template>
  <md-list-item class="list-versions" style="position:relative">
    <span id="version-top" v-if="first !== true" style="width: 2px;height: 21px;background-color: #757575;position: absolute;top: 0px;left: 11px;"></span>
    <span id="version-bottom" v-if="last !== true" style="width: 2px;height: 22px;background-color: #757575;position: absolute;top: 38px;left: 11px;"></span>
    <md-icon v-if="isActive(version) === true">radio_button_checked</md-icon>
    <md-icon v-else>radio_button_unchecked</md-icon>
    <div class="md-list-text-container" style="padding-left:15px;max-width: 250px;overflow: hidden;text-overflow: ellipsis;margin-right:auto">
      <template v-if="stateIs(VIEWING)">
        <span><b>V{{ version.versionNumber }}</b> <br v-if="version.description">{{ version.description }}</span>
        <span ><b>ID:</b> {{ version.filename }}</span>
      </template>

      <action-form   v-if="stateIs(EDITTING)"
              ref="actionForm"
              @state-change="stateChange"
              :version="version"
              :openedReport="openedReport"></action-form>
    </div>
    <action
      :state="getState"
      :version="version"
      :openedReport="openedReport"
      @state-change="stateChange"
      @submit-edit="submitEdit"
      @version-selected="$emit('version-selected')"
      @toggle-privacy="togglePrivacy"></action>
  </md-list-item>
</template>

<style>
  .list-versions > .md-list-item-container {
    padding:0;
  }
</style>

<script>
import Action         from './VersionsListAction.vue';
import ActionForm     from './VersionsListForm.vue';
import { mapGetters, mapActions }  from 'vuex';

export default {
  props : {
    first: {
      type : Boolean
    },
    last: {
      type: Boolean
    },
    state : {
      type     : String,
      required : true
    },
    version : {
      type     : Object,
      required : true
    },
    openedReport : {
      type     : Object,
      required : true
    }
  },
  components : {
    Action,
    ActionForm
  },
  data () {
    return {
      VIEWING  : 'VIEW',
      EDITTING : 'EDIT',
      myState  : this.state
    };
  },
  computed : {
    ...mapGetters({
      current : 'current'
    }),
    getState () {
      return this.myState;
    }
  },
  methods : {
    submitEdit () {
      this.$refs.actionForm.submitEdit();
    },
    stateChange (state) {
      this.myState = state;
    },
    stateIs (state) {
      return (this.myState === state);
    },
    togglePrivacy () {
      this.$refs.privacy.togglePrivacy();
    },
    isActive (version) {
      if (!this.current || !this.openedReport || !this.current.version || !this.current.report || !this.openedReport.report) {
        return false;
      }
      return version.id === this.current.version.id && this.current.report.id === this.openedReport.report.id;
    }    
  }
};
</script>
