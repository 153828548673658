import carboneVersions from '../../api/carboneVersions';
import * as types from '../mutation-types';

const state = {
  carboneVersions : [],
  currentVersion  : null
};

const getters = {
  carboneVersions : state => state.carboneVersions,
  currentVersion  : state => state.currentVersion
};

const actions = {
  getCarboneVersions ({ commit }) {
    return new Promise((resolve, reject) => {
      carboneVersions.getCarboneVersion((err, data) => {
        if (err) {
          console.log(err);
          return reject(err);
        }
        if (data.data && data.data.length > 0) {
          data.data = data.data.map(String);
        }
        commit(types.SET_CARBONE_VERSIONS, data.data);
        return resolve(data.data);
      });
    });      
  },
  setCarboneVersion ({ commit }, version) {
    if (version) {
      commit(types.SET_CURRENT_CARBONE_VERSION, version);
    }
  }
};

const mutations = {
  [types.SET_CARBONE_VERSIONS] (state, list) {
    state.carboneVersions = list;
  },

  [types.SET_CURRENT_CARBONE_VERSION] (state, version) {
    state.currentVersion = version + '';
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
