<template>
  <div class="full-height-wrapper" :class="{ shift }" style="padding: 100px 0px 30px 0px;">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shift: false
    }
  },
  methods: {
    toggle() {
      if (this.shift)
        this.unshift();
      else
        this.shift();
    },
    shift() {
      this.shift = true;
    },
    unshift() {
      this.shift = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.full-height-wrapper {
  padding-top: 9em;
}
</style>
