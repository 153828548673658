<template>
  <md-layout md-column>
    <md-layout class="actions">
      <md-menu md-size="4" ref="addTagMenu">
        <md-button class="md-primary md-raised" md-menu-trigger>
          <md-icon>add</md-icon> Tag
        </md-button>

        <md-menu-content>
          <div class="md-menu-container">
            <tag-editor @submit="addTag" :tag="{ tagName: 'my tag', tagColor: '000000' }" />
          </div>
        </md-menu-content>
      </md-menu>
    </md-layout>

    <md-layout class="list">
      <list />
    </md-layout>
  </md-layout>
</template>

<script>
import bus       from 'src/Components/bus';
import List      from '../list/TagsList.vue';
import Hermes    from 'plugins/Hermes';
import TagEditor from '../TagEditor.vue';

export default {
  data () {
    return {
    };
  },
  components : {
    List,
    TagEditor
  },
  computed : {
    getRef () {
      return 'TagsManager';
    }
  },
  methods : {
    addTag (tag) {
      if (!tag) {
        return this.$refs.addTagMenu.close();
      }
      Hermes.run({
        route : 'tag.addTag',
        data  : {
          name  : tag.tagName,
          color : tag.tagColor.hex
        }
      }, () => {
        bus.$emit('updateTagsList');
        this.$refs.addTagMenu.close();
      }, (data) => {
        this.toastError(data.message);
      });
    },
    opened () {
    },
    closed () {
    },
    open () {
      this.$refs.TagsManager.open();
    },
    close () {
      this.$refs.TagsManager.close();
    },
    toggle () {
      this.$refs.TagsManager.toggle();
    },
    action (action) {
      switch (action) {
        case 'open':
          this.open();
          break;
        case 'close':
          this.close();
          break;
        case 'toggle':
          this.toggle();
          break;
        default:
          this.toggle();
          break;
      }
    }
  },
  mounted () {
    bus.$on('TagsManager', this.action);
  },
  beforeDestroy () {
    bus.$off('TagsManager', this.action);
  }
};
</script>

<style lang="scss" scoped>
.actions {
  flex: 0;
  padding: 1em 0;
}

.list {
  flex: 1;
}
</style>
