<template>
  <md-whiteframe md-tag="md-toolbar" class="fixed" md-elevation="4">
    <md-button class="md-icon-button" @click="backToStudio">
      <md-icon>chevron_left</md-icon>
    </md-button>

    <h2 class="md-title" style="flex: 1">{{ $route.meta.title }}</h2>

    <toolbar-menu-toggler class="md-hide-medium-and-up"></toolbar-menu-toggler>
  </md-whiteframe>
</template>

<script>
import ToolbarMenuToggler from "./ToolbarMenuToggler.vue";

export default {
  components: {
    ToolbarMenuToggler
  },
  methods : {
    backToStudio () {
      this.$router.push({ name : 'studio' });
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
}
</style>
