<template>
  <md-layout md-column>
    <md-layout class="actions">
      <md-menu md-size="4" ref="addCatMenu">
        <md-button class="md-primary md-raised" md-menu-trigger>
          <md-icon>add</md-icon> Category
        </md-button>

        <md-menu-content>
          <div class="md-menu-container">
            <cat-editor @submit="addCat" :cat="{ catName: 'my category', catColor: '000000' }" />
          </div>
        </md-menu-content>
      </md-menu>
    </md-layout>

    <md-layout class="list">
      <list />
    </md-layout>
  </md-layout>
</template>

<script>
import bus       from 'src/Components/bus';
import List      from '../list/CatList.vue';
import Hermes    from 'plugins/Hermes';
import CatEditor from '../CatEditor.vue';
import { mapActions } from 'vuex';

export default {
  data () {
    return {
    };
  },
  components : {
    List,
    CatEditor
  },
  computed : {
    getRef () {
      return 'CatManager';
    }
  },
  methods : {
    ...mapActions({
      toast: 'toast',
      toastError: 'toastError'
    }),
    // Add a category
    addCat (cat) {
      if (!cat) {
        return this.$refs.addCatMenu.close();
      }
      Hermes.run({
        route : 'category.addCategory',
        data  : {
          name  : cat.catName,
          color : cat.catColor.hex
        }
      }, () => {
        bus.$emit('updateCatsList');
        bus.$emit('refreshList');
        this.$refs.addCatMenu.close();
      }, ({ message }) => this.toastError);
    },
    // Open the category manager modal
    open () {
      this.$refs.CatManager.open();
    },
    // Close the category manager modal
    close () {
      this.$refs.CatManager.close();
    },
    // Toggle (open if close, close if open) the category manager modal
    toggle () {
      this.$refs.CatManager.toggle();
    },
    // Choose what you want for the modal
    action (action) {
      switch (action) {
        case 'open':
          this.open();
          break;
        case 'close':
          this.close();
          break;
        case 'toggle':
          this.toggle();
          break;
        default:
          this.toggle();
          break;
      }
    }
  },
  mounted () {
    bus.$on('CatManager', this.action);
  },
  beforeDestroy () {
    bus.$off('CatManager', this.action);
  }
};
</script>

<style lang="scss" scoped>
.actions {
  flex: 0;
  padding: 1em 0;
}

.list {
  flex: 1;
}
</style>
