<template>
  <div class="vue-menu-content" :style="`top: ${top}px; left: ${x}; transform: translateX(${left})`" v-if="expanded">
    <slot></slot>
  </div>
</template>

<script>
import "./VueMenuContent.scss"

export default {
  name : 'VueMenuContent',
  data () {
    return {
      expanded: false,
      top: 0,
      left: 0,
      x: 0
    };
  },
  mounted () {
    this.$children.map(item => {
      if (item.$options.name === 'VueSubmenu')
        item.$on('triggerClick', data => this.$emit('triggerClick', data))
    })

    this.$on('expand', () => { this.expanded = true })
    this.$on('retract', () => { this.expanded = false })
  }
};
</script>

<style lang="scss" src="./VueMenuContent.scss"></style>