<template>
  <md-dialog ref="modalAddVersionFromLocalFile">
    <md-dialog-title style="margin-bottom:0px;">
      New template version
      <br><span style="font-size: 18px;color: gray;">from a local file</span>
    </md-dialog-title>
    

    <form ref="form">
      <md-dialog-content>
        <file-handler :file.sync="file"></file-handler>
        <md-input-container>
          <label>Version Tag X.X.X</label>
          <md-input v-model="version.numberVersion" required></md-input>
        </md-input-container>
        <md-input-container>
          <label>Description (optional)</label>
          <md-input v-model="version.description"></md-input>
        </md-input-container>
        <!-- <md-input-container>
          <label>Generated filename</label>
          <md-input v-model="version.reportNameEnd"></md-input>
        </md-input-container> -->
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="close">Cancel</md-button>
        <md-button class="md-primary" @click="submit">Add</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>
import bus                        from 'src/Components/bus';
import { mapGetters, mapActions } from 'vuex';
import Search                     from 'src/Components/search/BasicSearch.vue';
import FileHandler                from '../../FileHandler/FileHandler';

export default {
  props : {
    report : {
      type    : Object,
      default : null
    }
  },
  components : {
    Search,
    FileHandler
  },
  data () {
    return {
      modal   : null,
      file    : null,
      version : {
        description      : '',
        reportNameEnd    : '',
        numberVersion    : '',
        dataSample       : '{}',
        complementSample : '{}',
        enumSample       : '{}',
        translations     : '{}'
      }
    };
  },
  computed : {
    ...mapGetters({
      current               : 'current',
      templateListByCateg   : 'templateListByCateg',
      templateListWithCateg : 'templateListWithCateg'
    }),
    getRef () {
      return 'ModalAddVersion';
    }
  },
  methods : {
    ...mapActions({
      addVersion          : 'addVersion',
      setLastModifiedFile : 'setLastModifiedFile',
      setContinueRefresh  : 'setContinueRefresh',
      toastInfo           : 'toastInfo',
      toastSuccess        : 'toastSuccess',
      toastError          : 'toastError'
    }),

    /**
     * Add the version when form is submitted
     * Call request and update preview
     */
    submit () {
      var data = new FormData();
      for (var elem in this.version) {
        var value = this.version[elem];
        data.append(elem, value);
      }
      data.append('reportName', this.current.report.originalFileName);
      data.append('file', this.file);

      this.addVersion({
        idReport : this.report.id,
        data     : data
      }).then((msg) => {
        this.toastSuccess(msg);

        this.setContinueRefresh(true);
        bus.$emit('setJson', 'data');
        bus.$emit('refreshPreview');

        this.close();
      }, (msg) => {
        this.toastError(msg);
      });
    },

    /**
     * Open dialog
     */
    open () {
      bus.$emit('resetInputFilename');
      // Stop refresh with local template while modal is open
      this.setContinueRefresh(false);

      if (this.$refs.modalAddVersionFromLocalFile) {
        this.$refs.modalAddVersionFromLocalFile.open();
      }
    },

    /**
     * Close dialog
     */
    close () {
      if (this.$refs.modalAddVersionFromLocalFile) {
        this.$refs.modalAddVersionFromLocalFile.close();
      }
    },

    /**
     * Remove all data of the form
     * When the modal is close and then reopen, data will not be there
     */
    emptyData () {
      this.version = {
        description      : '',
        reportNameEnd    : '',
        numberVersion    : '',
        dataSample       : '{}',
        complementSample : '{}',
        enumSample       : '{}',
        translations     : '{}'
      };
      this.$refs.form.reset();
    }
  },
  mounted () {
    bus.$on('newVersionFromLocalFile', this.open);
  },
  beforeDestroy () {
    bus.$off('newVersionFromLocalFile', this.open); 
  }
};
</script>

<style lang="scss" scoped>
</style>
