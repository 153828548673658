import Container from './components/Container.vue';

const Toast = {
  _options: {
    position: 'left',
    timeout: 3000,
    title: null,
    content: null,
    theme: 'default'
  },
  mounted: false,
  install (Vue, options) {

    let _toastClass = Vue.extend(Container);
    let _instance = new _toastClass();

    _instance.$mount();

    Vue.toast = (data = {}) => {
      if (this.mounted === false) {
        document.getElementById('app').appendChild(_instance.$el);
        this.mounted = true;
      }
      const _options = Object.assign(JSON.parse(JSON.stringify(this._options)), data);

      _instance.addToast(_options);
    }

  }
};

export default Toast;