<template>
  <form @submit.prevent="submitEdit">
    <span>
      <md-input-container>
        <md-input placeholder="Version Tag" v-model="version.versionNumber" style="min-width:175px"></md-input>
      </md-input-container>
    </span>
    <span>
      <md-input-container>
        <md-input :placeholder="Description" v-model="version.description" style="min-width:175px"></md-input>
      </md-input-container>
    </span>
    <input type="submit" style="display: none" />
  </form>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  props : {
    version : {
      type     : Object,
      required : true
    },
    openedReport : {
      type     : Object,
      required : true
    }
  },
  data () {
    return {
      VIEWING : 'VIEW',
    };
  },
  computed : {
    getIdVN () {
      return this.version.id + 'VersionNumber';
    },
    getIdDescription () {
      return this.version.id + 'Description';
    }
  },
  methods : {
    ...mapActions({
      toastInfo     : 'toastInfo',
      toastSuccess  : 'toastSuccess',
      toastError    : 'toastError',
      updateVersion : 'updateVersion'
    }),
    submitEdit () {
      var data = {
        id   : this.version.id,
        data : {
          versionNumber : this.version.versionNumber,
          description   : this.version.description,
        }
      };

      this.updateVersion(data).then((message) => {
        this.toastSuccess(message);
        this.stateChange(this.VIEWING);
      }, (error) => {
        this.toastError(error);
      });
    },
    stateChange (state) {
      this.$emit('state-change', state);
    }
  }
};
</script>
