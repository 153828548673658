import list from '../../api/templateList';
import * as types from '../mutation-types';

const state = {
  templateListByCateg      : [],
  templateListWithCateg    : [],
  templateListWithoutCateg : []
};

const getters = {
  // List
  templateListByCateg         : state => state.templateListByCateg,
  templateListWithCateg       : state => state.templateListWithCateg,
  templateListWithoutCateg    : state => state.templateListWithoutCateg,
  // List length
  templateListByCategLength   : state => state.templateListByCateg.length,
  templateListWithCategLength : state => state.templateListWithCateg.length
};

const actions = {
  getTemplateListWithCateg ({ commit }) {
    list.getTemplateListWithCateg(list => {
      commit(types.GET_TEMPLATE_LIST_WITH_CATEG, { list });
    });
  },
  getTemplateListWithoutCateg ({ commit }) {
    list.getTemplateListWithoutCateg(list => {
      commit(types.GET_TEMPLATE_LIST_WITHOUT_CATEG, { list });
    });
  },
  getTemplateListByCateg ({ commit }) {
    list.getTemplateListByCateg(list => {
      commit(types.GET_TEMPLATE_LIST_BY_CATEG, { list });
    });
  }
};

const mutations = {
  [types.GET_TEMPLATE_LIST_WITH_CATEG] (state, { list }) {
    state.templateListWithCateg = list;
  },
  [types.GET_TEMPLATE_LIST_WITHOUT_CATEG] (state, { list }) {
    state.templateListWithoutCateg = list;
  },
  [types.GET_TEMPLATE_LIST_BY_CATEG] (state, { list }) {
    state.templateListByCateg = list;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};