<template>
  <div class="vue-menu-group">
    <slot></slot>
  </div>
</template>

<script>
import bus from '../bus';
import "./VueMenuGroup.scss"

export default {
  name       : 'VueMenuGroup',
  components : {
  },
  data () {
    return {
      lock : false
    };
  },
  methods : {
    getChildrenComponents(name) {
      let children = []

      this.$children.map(item => {
        if (item.$options.name === name || name === '*')
          children.push(item)
      })

      return children
    },
    emitChildrenEvent(children, event, data) {
      children.map(item => {
        item.$emit(event, data)
      })
    },
    untriggerAll () {
      const children = this.getChildrenComponents('VueMenu')

      this.emitChildrenEvent(children, 'untrigger')
      return
    }
  },
  mounted () {
    bus.$on('trigger', (uid) => {
      this.lock = true;
    });

    bus.$on('triggerHover', (uid) => {
      if (this.lock) {
        this.untriggerAll();
        bus.$emit(uid, 'trigger');
      }
    });

    bus.$on('untrigger', (uid) => {
      this.lock = false;
      bus.$emit(uid, 'untrigger');
    });
  }
};
</script>

<style lang="scss" src="./VueMenuGroup.scss"></style>