<template>
  <md-dialog ref="newReport">
      <md-dialog-title>New template</md-dialog-title>

      <form ref="form" enctype="multipart/form-data" v-on:submit.prevent="add">
          <md-dialog-content style="width: 550px" class="grid grid-container grid-column">

              <grid column container>
                  <div class="grid">
                    <file-handler :file.sync="file"></file-handler>
                  </div>

                  <grid container :horizontal-gutter="20">
                    <grid>
                      <md-input-container>
                        <label>Name of the template</label>
                        <md-input name="reportName" v-model="newReport.reportName" required></md-input>
                      </md-input-container>
                    </grid>

                    <grid>
                        <md-input-container>
                            <label>Description</label>
                            <md-input name="description" v-model="newReport.reportDescr"></md-input>
                        </md-input-container>
                    </grid>
                </grid>

                  <grid>
                    <v-select :options="categories" label="name" style="width: 100%" :placeholder="Category" v-model="newReport.categorie"></v-select>
                  </grid>
              </grid>

              <grid container :horizontal-gutter="20">
                  <grid>
                      <md-input-container>
                          <label>Document name when generated</label>
                          <md-input name="reportNameEnd" v-model="newReport.renderName"></md-input>
                      </md-input-container>
                  </grid>
                  <grid>
                      <md-input-container>
                          <label>Version Tag (x.x.x)</label>
                          <md-input name="numberVersion" v-model="newReport.versionNumber" required></md-input>
                      </md-input-container>
                  </grid>
              </grid>
          </md-dialog-content>

          <md-dialog-actions>
              <md-button @click="closeDialog('newReport')" class="md-pimary">cancel</md-button>
              <md-button type="submit" class="md-pimary">add</md-button>
          </md-dialog-actions>
      </form>
  </md-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import bus                        from './bus';
import FileHandler                from './FileHandler/FileHandler';

export default {
  components : {
    FileHandler
  },
  data () {
    return {
      newReport : {
        categorie        : null,
        categories       : [],
        report           : null,
        listReports      : [],
        choice           : false,
        validCurrent     : false,
        file             : null,
        originalFileName : null,
        libelle          : 'Upload a template',
        reportName       : '',
        reportDescr      : '',
        renderName       : '',
        versionNumber    : '1.0.0',
        reportFile       : null
      },
      lastModifiedFile : null,
      reportName       : null,
      file             : null
    };
  },
  computed : mapGetters({
    current             : 'current',
    idFileServer        : 'idFileServer',
    categories          : 'categories',
    templateListByCateg : 'templateListByCateg'
  }),
  methods : {
    ...mapActions({
      addReport           : 'addReport',
      getCategories       : 'getCategories',
      setContinueRefresh  : 'setContinueRefresh',
      setLastModifiedFile : 'setLastModifiedFile',
      toastSuccess        : 'toastSuccess',
      toastError          : 'toastError'
    }),

    customLabelByName : function (option) {
      return `${option.name}`;
    },
    customLabelReport : function (option) {
      return `${option.originalFileName}`;
    },

    /**
     * Check if the version number looks like x.x.x
     */
    isVersionNumberCorrect : function (number) {
      var ex = number.split('.');
      if (typeof ex[0] === 'undefined' || typeof ex[1] === 'undefined' || typeof ex[2] === 'undefined') {
        return false;
      }
      if (/^\d+$/.test(ex[0]) === false || /^\d+$/.test(ex[1]) === false || /^\d+$/.test(ex[2]) === false) {
        return false;
      }
      return true;
    },

    /**
     * Function call when the form is submited
     * It check the form and call the request
     */
    add : function () {
      if (this.file === undefined || this.file === '') {
        return this.toastError('You must upload a file');
      }

      // Check the form
      this.newReport.file = this.file;

      // Check report name
      if (this.newReport.choice === 'report' && this.newReport.reportName.trim() === '') {
        return this.toastError('You must set a name to the report');
      }

      // Check version number
      if (this.newReport.versionNumber.trim() === '') {
        return this.toastError('You must set a number\'s version');
      }

      if (this.isVersionNumberCorrect(this.newReport.versionNumber) === false) {
        return this.toastError('Error with version number (x.x.x)');
      }

      // Insert the report
      this.insertReport();
    },

    /**
     * Add the report in database
     */
    insertReport : function () {
      let categ = (this.newReport.categorie === null || this.newReport.categorie === undefined) ? -1 : this.newReport.categorie.id;

      var formData = new FormData();
      formData.append('idCategory', categ);
      formData.append('description', this.newReport.reportDescr);
      formData.append('reportNameEnd', this.newReport.renderName);
      formData.append('numberVersion', this.newReport.versionNumber);
      formData.append('reportName', this.newReport.reportName);
      formData.append('file', this.file);

      // Call the request to add the report
      this.addReport(formData).then((msg) => {
        this.file = null;
        this.toastSuccess(msg);

        // Can refresh locally
        this.setContinueRefresh(true);

        bus.$emit('setJson', 'data');
        bus.$emit('refreshPreview');
        bus.$emit('refreshList', 1);

        // Close menu and dialog
        this.closeDialog('newReport');
        bus.$emit('menu', 'close');
      }, (msg) => {
        this.toastError(msg);
      });
    },

    /**
     * Open this dialog
     */
    openDialog (ref) {
      bus.$emit('resetInputFilename');
      // Stop refresh with local template wile modal is open
      this.setContinueRefresh(false);

      var elem = this.$refs[ref];
      if (elem !== undefined) {
        elem.open();
      }
    },

    /**
     * Close this dialog
     */
    closeDialog (ref) {
      var elem = this.$refs[ref];
      elem.close();
    },

    /**
     * Reset the form
     */
    reset() {
      this.newReport.reportName = '';
      this.newReport.reportDescr = '';
      this.newReport.renderName = '';
      this.newReport.reportFile = null;
    }

  },
  mounted () {
    bus.$on('displayAddReport', (options) => {
      this.getCategories().then(() => {
        this.reset();
        this.openDialog('newReport');
      });
    });
    bus.$on('fileChange', (originalFileName, name) => {
      this.newReport.originalFileName = originalFileName;
      this.reportName = name;
    });
    bus.$on('beginCheck', (lastModifiedFile) => {
      this.validCurrent = true;
      this.lastModifiedFile = lastModifiedFile;
    });
  },
  beforeDestroy () {
    bus.$off('displayAddReport');
    bus.$off('fileChange');
    bus.$off('beginCheck');
  }
};
</script>
