const constants = require('./const.js');

const Hermes = {
  init (options, routes) {
    Object.assign(this._options, options);
    this.api = routes;
  },
  listeners : [],
  api      : {},
  _options : {
    port        : window.location.port,
    protocol    : window.location.protocol,
    baseUrl     : window.location.hostname,
    method      : constants.GET,
    credentials : 'same-origin',
    headers     : {
      Accept         : 'application/json',
      'Content-Type' : 'application/json'
    }
  },
  addRequestListener : function (listener) {
    this.listeners.push(listener);
  },
  notifyRequest : function () {
    for (var i = 0; i < this.listeners.length; i++) {
      this.listeners[i](this.pendingRequests);
    }
  },
  pendingRequests : 0,
  run (options, cb, cbError) {
    var params  = options.params;
    var body    = options.data;
    var api     = eval(`this.api.${options.route}`);
    var route   = this._getRoute(api.route, params);

    options = Object.assign({}, this._options, options);
    options.method = (typeof api.method !== 'undefined') ? (api.method) : (options.method);
    options.queries = (typeof options.queries !== 'undefined') ? options.queries : '';

    var url = `${options.protocol}//${options.baseUrl}:${options.port}${route}${options.queries}`;

    body = (options.headers['Content-Type'] === 'application/json') ? JSON.stringify(body) : body;
    this.pendingRequests++;
    this.notifyRequest();
    fetch(url, {
      credentials : options.credentials,
      method      : options.method,
      headers     : options.headers,
      body        : body
    }).then((response) => {
      response.text().then((data) => {
        this.pendingRequests--;
        this.notifyRequest();
        data = JSON.parse(data);
        if (data.success === false) {
          // If the login athentication failed, redirect the user to login

          if (data.data && data.data.redirect) {
            window.location.href = '/#/login';
            //toast.addToast(data.data.message, 'error');
          }
          if (typeof cbError === 'function') {
            return cbError(data);
          }
        }
        return cb(data);
      });
    });
  },
  _getRoute(route, params) {
    if (typeof params === 'undefined') {
      return route;
    }
    else {
      for (let i in params) {
        let param = params[i]

        route = route.replace(`{${i}}`, param)
      }
      return route;
    }
  }
};

export default Hermes;
