import devConfig from './dev.json'
import prodConfig from './prod.json'

module.exports = function() {
  const env = process.env.NODE_ENV

  if (env === 'development')
    return devConfig
  if (env === 'production')
    return prodConfig
}()