<template>
  <div style="height: 100%; position: relative; overflow: hidden">
    <div ref="jsoneditor" id="jsoneditor" style="position: absolute; width: 100%; height:100%"></div>
  </div>
</template>

<style>
  #jsoneditor > .jsoneditor > .jsoneditor-menu {
    display:none;
  }
  #jsoneditor > .jsoneditor > .jsoneditor-outer.has-main-menu-bar {
    margin: 0;
    padding:0;
  }
</style>

<script>
import bus                        from './bus';
import JSONEditor                 from 'jsoneditor';
import { mapGetters, mapActions } from 'vuex';

export default {
  data () {
    return {
      editormode     : 1,
      refreshTimeout : null,
      editor         : null,
      container      : null,
      options        : {
        onChange        : this.refreshPreviewSmartly,
        enableSort      : false,
        enableTransform : false,
        statusBar       : false,
        search          : true,
        mainMenuBar     : true
      }
    };
  },
  props: {
    jsoneditormode: Number,
  },
  watch: { 
    jsoneditormode: function(newVal, oldVal) {
      this.changeJsoneditorMode(newVal);
    }
  },
  computed : mapGetters([
    'translations',
    'currencySource',
    'currencyTarget',
    'dataForCarbone',
    'enumsForCarbone',
    'complementForCarbone',
    'current',
    'old',
    'idFileServer',
    'liveReload',
    'lang',
    'timezone'
  ]),
  methods : {
    ...mapActions({
      render     : 'render',
      setCanSave : 'setCanSave'
    }),
    refreshPreviewSmartly : function () {
      this.setCanSave(true);

      if (this.current.report.id !== undefined && this.liveReload) {
        if (this.refreshTimeout) {
          clearTimeout(this.refreshTimeout);
        }
        this.refreshTimeout = setTimeout(() => {
          this.refreshPreview();
        }, 900);
      }
    },
    /**
     * Check if data are corrects, then render the report
     */
    refreshPreview : function () {
      if (this.current.report === undefined ||
          this.current.report.id === undefined ||
          this.current.version.id === undefined) {
        return;
      }
      bus.$emit('log', {
        message : 'Data modified',
        type    : 'info'
      });
      if (this.inspectJsoneditor() === false) {
        bus.$emit('log', {
          message : 'Incorrect JSON data',
          type    : 'error'
        });
      } else {
        this.sendTemplateAndData();
      }
    },
    /**
     * Call the render function and display log depending on response
     */
    sendTemplateAndData : function () {
      bus.$emit('log', {
        message : 'Preview processing...',
        type    : 'info'
      });
      var data = {
        data         : JSON.stringify(this.dataForCarbone),
        complement   : JSON.stringify(this.complementForCarbone),
        enum         : JSON.stringify(this.enumsForCarbone),
        translations : JSON.stringify(this.translations),
        currencySource: this.currencySource,
        currencyTarget: this.currencyTarget,
        convertTo    : 'pdf',
        lang         : this.lang,
        timezone     : this.timezone,
        filename     : this.current.version.filename,
        reportName   : this.current.version.reportName,
        shareKey     : null
      };
  
      var idVersion = this.current.version.id;
      data.filename = this.idFileServer;
      this.updateExportFormatList(data.filename);
      // IMPORTANT: the template must be the last thing added to the form, otherwise multer hangs on server side. https://github.com/expressjs/multer/issues/53
      if (this.$route.params.key !== undefined) {
        data.shareKey = this.$route.params.key;
      }
      var renderingStartTime = Date.now();
      // Render the report
      this.render({
        idVersion : idVersion,
        data      : data
      })
        .then(() => {
          // Success, set log and display preview
          var currentTime = new Date().toLocaleTimeString();
          var duration = Date.now() - renderingStartTime;
          bus.$emit('log', {
            message : 'Preview updated at ' + currentTime + ' in ' + duration + 'ms',
            type    : 'success'
          });
          bus.$emit('displayPreview');
        })
        .catch((err) => {
          // Error, display the error message
          if (err !== null) {
            bus.$emit('log', {
              message : 'Processing has failed failed: ' + err,
              type    : 'error'
            });
          } else {
            bus.$emit('log', {
              message : 'Preview processing has failed',
              type    : 'error'
            });
          }
        });
    },
    updateExportFormatList (filename) {
      let re = /(?:\.([^.]+))?$/;
      let ext = re.exec(filename);
      if (filename !== undefined && ext !== undefined) {
        this.$store.dispatch('getExportFormatList', ext[1]);
      }
    },
    /**
     * @param val
     * 1 => DATA
     * 2 => COMPLEMENT
     * 3 => ENUM
     * Change JSON depending on value passed in parameter
     */
    changeJsoneditorMode : function (val) {
      if (this.editor !== null) {
        this.inspectJsoneditor();
        this.editormode = this.jsoneditormode;
        switch (val) {
          case 1:
            this.editor.set(this.dataForCarbone);
            break;
          case 2:
            this.editor.set(this.complementForCarbone);
            break;
          case 3:
            this.editor.set(this.translations);
            break;
          case 4:
            this.editor.set(this.enumsForCarbone);
            break;
          default:
            this.editor.set(this.dataForCarbone);
        }
      }
    },

    /**
     * Check if data are correct in jsoneditor and
     * update it in vuex
     */
    inspectJsoneditor : function () {
      var data = {};
      try {
        data = this.editor.get();
        if (this.editormode === 1) {
          this.$store.dispatch('setDataForCarbone', data);
        } else if (this.editormode === 2) {
          this.$store.dispatch('setComplementForCarbone', data);
        } else if (this.editormode === 3) {
          this.$store.dispatch('setTranslations', data);
        } else if (this.editormode === 4) {
          this.$store.dispatch('setEnumsForCarbone', data);
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    resetData : function () {
      this.$store.dispatch('setDataForCarbone', JSON.parse('{}'));
      this.$store.dispatch('setComplementForCarbone', JSON.parse('{}'));
      this.$store.dispatch('setEnumsForCarbone', JSON.parse('{}'));
      this.$store.dispatch('setTranslations', JSON.parse('{}'));
      this.editor.set(this.dataForCarbone);
      this.jsoneditormode = 1;
    },
    setJSON : function (type) {
      if (type === 'data') {
        this.editor.set(this.dataForCarbone);
      } else if (type === 'enum') {
        this.editor.set(this.enumsForCarbone);
      } else if (type === 'complement') {
        this.editor.set(this.complementForCarbone);
      } else if (type === 'translations') {
        this.editor.set(this.translations);
      }
    }
  },
  mounted () {
    this.container = document.getElementById('jsoneditor');
    this.editor = new JSONEditor(this.container, this.options);
    this.editor.setMode('code');
    this.editor.aceEditor.commands.addCommand({
      name    : 'Save and Refresh',
      bindKey : {
        win : 'Ctrl-S',
        mac : 'Cmd-S'
      },
      exec : () => {
        if (this.current.canSave) {
          bus.$emit('saveVersion');
        }
      }
    });

    bus.$on('setJson', this.setJSON);
    bus.$on('updateDataJson', this.inspectJsoneditor);
    bus.$on('refreshPreview', this.refreshPreview);
    bus.$on('resetData', this.resetData);
  },
  beforeDestroy () {
    bus.$off('setJson', this.setJSON);
    bus.$off('updateDataJson', this.inspectJsoneditor);
    bus.$off('refreshPreview', this.refreshPreview);
    bus.$off('resetData', this.resetData);
  }
};
</script>

<style>
  div.jsoneditor-menu a.jsoneditor-poweredBy {
    display: none;
  }
  .carbone-mode {
    width: auto;
    min-width: 340px;
    background: rgb(248, 248, 248);
    position: absolute;
    bottom: 0;
    z-index: 4;
    border-radius: 2pt 2pt 0 0;
    text-align: center;
    right:0;
  }
</style>
