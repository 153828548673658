<template>
  <div class="searchbar-container">
    <md-whiteframe md-elevation="1" style="border-top: 1px solid rgb(219, 219, 219) !important;box-shadow:none;">
      <md-icon style="position: absolute;z-index: 4;top: 13px;left: 20px;width: 20px;font-size: 20px;">search</md-icon>
      <input type="text" placeholder="Find a template..." class="searchbar-input white" style="padding: 15px 0px 15px 72px !important;font-size:1em;" v-model="term" ref="inputSearchReport" />
      <div class="searchbar-results" v-show="checkShow()">
        <md-whiteframe md-elevation="1">
          <reports-list-dense :reports="methodResults"></reports-list-dense>
        </md-whiteframe>
      </div>
    </md-whiteframe>
  </div>
</template>

<script>
import ReportsListDense from '../ReportsListDense.vue';
import bus from '../bus';

export default {
  components : {
    ReportsListDense
  },
  props : {
    list : {
      type     : Array,
      required : true
    }
  },
  computed : {
    getList () {
      return this.list;
    }
  },
  data () {
    return {
      term          : '',
      methodResults : [],
      options       : {
        keys      : ['originalFileName', 'tags.name'],
        threshold : 0.2
      },
      showResults : true
    };
  },
  methods : {
    checkShow () {
      if (this.showResults && this.term.length > 0)
      {return (true);}
      else
      {return (false);}
    }
  },
  watch : {
    term () {
      this.$search(this.term, this.list, this.options).then(results => {
        this.methodResults = results;
      });
    }
  },
  mounted () {

    bus.$on('focusInputSearchReport', () => {
      setTimeout(() => {
        this.showResults = true;
        this.$refs.inputSearchReport.focus();
        this.$refs.inputSearchReport.select();
      }, 100);
    });
  },
  beforeDestroy () {
    bus.$off('focusInputSearchReport');
  }
};
</script>