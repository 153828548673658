import store from '../../store';

const VueRole = {
  install (Vue) {
    Vue.directive('role', {
      bind(el, binding, vnode, oldVnode) {
        // Role de l'user, aussi donné par le serveur
        var USERROLE = store.getters.role;
        var role = binding.expression.trim().split(',');

        if (role.indexOf(USERROLE) < 0)
          vnode.elm.parentElement.removeChild(vnode.elm)
      }
    })
  }
};

export default VueRole;
