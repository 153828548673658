<template>
  <div class="logsbar-wrapper" :style="`height: ${logsBarHeight}`" @click="toggleLogsbar">
    <div class="logsbar md-whiteframe md-whiteframe-3dp" :style="`height: ${logsBarHeight}`">
      <div id="logsBarScroll" class="logslist" style="height: 100%">
        <div class="logslist-wrapper">
          <div class="logslist-log" v-for="log in logsToDisplay" :key="log.id">
            <log-icon :type="log.type"></log-icon>
            {{ log.message }}
          </div>
        </div>
      </div>
    </div>
    <div class="logsbar-toggler-wrapper">
      <!-- <app-tour-step-item
                step-id="logsbar"
                title="Logsbar"
                subtitle=""
                body="tutoLogsbarBody"
                bodywidth="70vw"
                bodytop="15%"
                bgcolor="teal.500"
                bodyleft="25%"
                wrapper-style="FILLED">
      </app-tour-step-item> -->
    </div>
  </div>
</template>

<script>
import bus     from './bus';
import LogIcon from './LogIcon.vue';

export default {
  data () {
    return {
      logsToDisplay : [],
      allLogs       : [],
      isExpanded    : false
    };
  },
  components : {
    LogIcon
  },
  computed: {
    logsBarHeight() {
      if (this.isExpanded)
        return 30 * this.allLogs.length + 'px'
      return '30px'
    }
  },
  watch : {
    logsBarHeight : (newValue) => {
      let logsScroll = document.getElementById('logsBarScroll')

      // Add timeout, else the div has the the right height and there is nothing to scroll
      setTimeout(() => {
        logsScroll.scrollBy(0, parseInt(newValue))
      }, 10)
    }
  },
  methods : {
    toggleLogsbar () {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded) {
        this.logsToDisplay = this.allLogs
      } else {
        this.logsToDisplay = [this.allLogs[this.allLogs.length - 1]]
      }
    }
  },
  mounted () {
    bus.$on('log', (log) => {
      this.allLogs.push(log);

      if (!this.isExpanded) {
        this.logsToDisplay = [log];
      }
    });
    bus.$on('toggleLogsbar', this.toggleLogsbar);
  },
  beforeDestroy () {
    bus.$off('log');
    bus.$off('toggleLogsbar', this.toggleLogsbar);
  }
};
</script>
