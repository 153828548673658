import Vue                        from 'vue';
import App                        from './App.vue';
import VueRouter                  from 'vue-router';
import { Photoshop, Chrome }      from 'vue-color';
import VueFuse                    from 'vue-fuse';
import VueClipboard               from 'vue-clipboard2';
import NewStudio                  from './Components/NewStudio.vue';
import Dashboard                  from './Components/dashboard/Dashboard.vue';

import CatManager                 from './Components/categories/modals/CatManager.vue';
import TagsManager                from './Components/tags/modals/TagsManager.vue';

import VueMaterial    from 'vue-material';
import store          from './store';
import date           from './date.js';
import Hermes         from 'plugins/Hermes';
import VueMenu        from 'plugins/VueMenu';
import Ptah           from 'plugins/Ptah';
import VueSelect      from 'plugins/VueSelect/src';
import Toast          from 'plugins/Toast';
import VueRole        from 'plugins/VueRole';

import APIDesc        from './api/description.js';

var css   = '  font-size: 25pt;\
        color: #fff;\
        background: rgb(103, 58, 183);\
        padding: 0 20px;\
        border-radius: 3pt';

console.log('%cCarbone', css);

Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(VueFuse);
Vue.use(VueClipboard);
Vue.use(VueMenu);
Vue.use(Ptah);
Vue.use(VueSelect);
Vue.use(Toast);
Vue.use(VueRole);

Vue.component('v-select'   , VueSelect);
Vue.component('photoshop'   , Photoshop);
Vue.component('chrome'     , Chrome);


var lang = localStorage.getItem('language');

if (lang !== 'en' && lang !== 'fr') {
  lang = 'en'
}

date.init(lang);

const studioName = "Carbone Studio";
const titleSep = " - ";

const routes = [
  { path : '/studio'                                  , name : 'studio'           , component : NewStudio , meta : { title : studioName }},
  { path      : '/'                                        , name      : 'dashboard'        , component : Dashboard ,
    children  : [
      { path : 'categories'          , name : 'categoriesManager' , component : CatManager       , meta : { title : 'Categories' }},
      { path : 'tags'                , name : 'tagsManager'       , component : TagsManager      , meta : { title : 'Tags' }},
      { path : '*', redirect : '/studio'}
    ]
  },
  { path : '*', redirect : '/studio' }
];

const router = new VueRouter({
  mode : 'hash',
  routes
});

router.onReady(function () {
  router.push('studio');
});

/**
 * Set title before each route
 */
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if (to.meta.title) {
    to.meta.fullTitle = to.meta.title !== studioName ? to.meta.title + titleSep + studioName : studioName;
  }

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.fullTitle;
  }
  next();
});

/**
 * Check if user is logged in
 */
router.beforeEach(function (to, from, next) {
  store.dispatch('loginCheck', ({
    router : router,
    cb     : (result) => {
      if (result.success === false) {
        return window.location.replace(result.data.redirect);
      }

      return next();
    }
  }));
});

Vue.material.registerTheme({
  default : {
    primary : 'deep-purple',
    accent  : {
      color     : 'deep-purple',
      hue       : 200,
      textColor : 'white'
    },
    warn       : 'red',
    background : 'white'
  }
});

Hermes.init({}, APIDesc);

new Vue({
  el     : '#app',
  router,
  store,
  render : h => h(App)
});
