<template>
  <div class="carbone-toast" :class="`${options.theme}-theme toast-${options.position}`" @click="destroy" @mouseover="lock" @mouseout="unlock">
    <div class="toast-title"><md-icon v-if="icon">{{ icon }}</md-icon> <span v-if="options.title" >{{ options.title }}</span></div>
    <div v-if="options.content" class="toast-content">{{ options.content }}</div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      locked: false,
      themeIcon: {
        success: 'check',
        error: 'close',
        default: null
      }
    }
  },
  computed: {
    icon() {
      return this.themeIcon[this.options.theme];
    }
  },
  mounted() {
    setTimeout(this.destroy, this.options.timeout);
  },
  methods: {
    destroy() {
      this.$emit('destroy', this.options.$id);
    },
    lock() {
      this.locked = true;
    },
    unlock() {
      this.locked = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.carbone-toast {
  float: left;
  clear: both;
  padding: 10px 20px;
  margin: 2px 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 2pt;

  &.error-theme {
    background: black;
    color: white;
  }

  &.default-theme {
    color: inherit;
    background: #fff;
  }

  &.success-theme {
    background: #673ab7;
    color: #fff;
  }

}
</style>
