<template>
  <div class="vue-menu-item" :class="{ clickable, disabled }" @click="click($event)">
    <slot></slot>
  </div>
</template>

<script>
import "./VueMenuItem.scss"

export default {
  name       : 'VueMenuItem',
  components : {
  },
  props : {
    clickable : {
      type : Boolean,
      default : true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    };
  },
  methods : {
    click (e) {
      if (this.clickable && !this.disabled)
        this.$emit('click');
    }
  }
};
</script>

<style lang="scss" src="./VueMenuItem.scss"></style>