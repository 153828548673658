import Vue from 'vue';

export default {
  toast(state, data) {
    Vue.toast(data);
  },
  toastError(state, message = null) {
    Vue.toast({
      title: message,
      theme: 'error'
    });
  },
  toastSuccess(state, message = null) {
    Vue.toast({
      title: message,
      theme: 'success'
    });
  },
  toastInfo(state, message = null) {
    Vue.toast({
      title: message,
      theme: 'default'
    });
  }
}