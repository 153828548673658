<template>
    <md-button-toggle v-on:click.native="updateDisplayByCateg">
        <md-button :class="state == true ? 'md-toggle' : ''">
            <md-icon style="font-size: 20px;">view_list</md-icon> Group by category
        </md-button>
    </md-button-toggle>
</template>

<script>
import bus from './bus';

export default {
  props   : ['state'],
  methods : {
    updateDisplayByCateg () {
      bus.$emit('toggleGroupByCateg');
    }
  }
};
</script>