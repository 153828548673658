<template>
  <div>
    <md-dialog ref="versionInfo">
          <md-dialog-content style="min-width: 30vw;">
            <md-layout md-gutter="16" v-for="data in getDataList" :key="data.key" class="version-line">
              <md-layout md-flex="35" md-align="end"><span class="version-info-id">{{ data.key }}</span></md-layout>
              <md-layout md-flex="55">{{ data.value }}</md-layout>

              <div class="version-icon-button">
                <md-button class="md-icon-button"
                           v-if="data.icon !== undefined"
                           v-clipboard:copy="data.valueToCopy"
                           v-clipboard:success="onCopy"
                           v-clipboard:error="onError">
                  <md-icon class="version-clipboard">{{ data.icon }}</md-icon>
                  <md-tooltip md-direction="right">Copy template ID to clipboard</md-tooltip>
                </md-button>
              </div>
            </md-layout>
            <!-- <md-list>
              <md-list-item><span class="version-info-id">Template ID</span><span class="version-info-value">{{ current.version.filename }}</span></md-list-item>
              <md-list-item><span class="version-info-id">Date created</span><span class="version-info-value">{{ getDateCreated }}</span></md-list-item>
              <md-list-item><span class="version-info-id">Date updated</span><span class="version-info-value">{{ getDateUpdated }}</span></md-list-item>
            </md-list> -->
          </md-dialog-content>

          <md-dialog-actions>
              <md-button @click="closeDialog('versionInfo')" class="md-primary">close</md-button>
          </md-dialog-actions>
        </md-dialog>
  </div>
</template>

<script>

import bus              from '../../bus';
import date             from '../../../date';
import { mapActions, mapGetters }   from 'vuex';

export default {
  computed : {
    ...mapGetters({
      current      : 'current',
      idFileServer : 'idFileServer'
    }),
    getDataList () {
      return [{
        key   : 'Filename',
        value : this.current.report.originalFileName
      }, {
        key   : 'Version',
        value : this.current.version.versionNumber
      }, {
        key         : 'Template ID',
        value       : this.truncatedTemplateID,
        valueToCopy : this.idFileServer,
        icon        : 'file_copy'
      }, {
        key   : 'Date created',
        value : this.getDateCreated
      }, {
        key   : 'Date updated',
        value : this.getDateUpdated
      }]
    },
    truncatedTemplateID () {
      if (this.idFileServer === undefined) {
        return '';
      }

      return this.idFileServer.slice(0, 22) + '...';
    },
    getDateCreated () {
      return (date.getFormatDate(this.current.version.dateCreated));
    },
    getDateUpdated () {
      return (date.getFormatDate(this.current.version.dateUpdated));
    }
  },
  methods : {
    ...mapActions({
      toastSuccess : 'toastSuccess',
      toastError   : 'toastError'
    }),

    /**
     * Open this dialog
     */
    openDialog (ref) {
      var elem = this.$refs[ref];
      if (typeof elem !== 'undefined') {
        elem.open();
      }
    },
    closeDialog (ref) {
      var elem = this.$refs[ref];
      elem.close();
    },
    open () {
      this.openDialog('versionInfo');
    },
    onCopy () {
      this.toastSuccess('Template ID copied');
    },
    onError () {
      this.toastError('Cannot copy template ID');
    }
  },
  mounted () {
    bus.$on('infoVersion', this.open);
  },
  beforeDestroy () {
    bus.$off('infoVersion', this.open);
  }
};
</script>

<style lang="scss" scoped>
.version-line {
  position: relative;
}
.version-icon-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-15%);
  background: #fff;
}
.version-clipboard {
  font-size: 1.4em;
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.2s;
}
.version-clipboard:hover {
  color: rgba(0, 0, 0, 1);
}
.version-info-id {
  font-weight: 500;
    margin-bottom: -5px;
    color: rgba(0, 0, 0, 0.3);
}
</style>
