<template>
  <div class="icon" :class="getIcon.attr">
    <md-icon>{{ getIcon.icon }}</md-icon>
  </div>
</template>

<script>
export default {
  props : [
    'type'
  ],
  computed : {
    getIcon () {
      switch (this.type) {
        case 'info':
          return this.genIcon('info', 'indigo-text');
        case 'warning':
          return this.genIcon('warning', 'orange-text');
        case 'error':
          return this.genIcon('error', 'red-text');
        case 'success':
          return this.genIcon('done', 'light-green-text darken-3');
      }
    }
  },
  methods : {
    genIcon (icon, attr) {
      return {
        icon : icon,
        attr : attr
      };
    }
  }
};
</script>
