import * as types from '../mutation-types';

if (localStorage.getItem('isCarboneLiveReloadEnabled') === undefined ||
    localStorage.getItem('isCarboneLiveReloadEnabled') === null) {
  localStorage.setItem('isCarboneLiveReloadEnabled', true);
}

const state = {
  liveReload        : localStorage.getItem('isCarboneLiveReloadEnabled') === 'true',
  lastModifiedFile  : null
};

const getters = {
  liveReload        : state => state.liveReload,
  lastModifiedFile  : state => state.lastModifiedFile
};

const actions = {

  /**
   * Update last modified file time
   * @param {Date} last : Last time file has been update
   */
  setLastModifiedFile ({ commit }, last) {
    commit(types.SET_LAST_MODIFIED_FILE, last);
  }
};

const mutations = {
  [types.SET_LIVE_RELOAD] (state, data) {
    localStorage.setItem('isCarboneLiveReloadEnabled', data);
    state.liveReload = data;
  },
  [types.SET_LAST_MODIFIED_FILE] (state, last) {
    state.lastModifiedFile = last;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
