<template>
  <div class="full-width">
    <div v-if="!nativeFileSystemIsActive">
      <label for="file-upload" class="input-label">Template</label><br />
      <input id="file-upload" type="file" class="choose" @change="newFile" />
      <br/><br/>
      <span @click="openArticleLiveReload">
        <md-chip class="md-warn" style="font-weight: 600;">Live-reload not available</md-chip>
      </span>
    </div>
    <div v-else>
      <md-input-container style="margin-bottom:20px">
        <label>Template</label>
        <md-input ref="file-input" required v-model="filename" @focus="askFile"></md-input>
        <md-icon>attach_file</md-icon>
      </md-input-container>
      <span @click="openArticleLiveReload">
        <md-chip  style="font-weight: 600;border: 1px solid black;background-color: transparent;"><md-icon style="margin-top: -6px;">sync</md-icon> Live-reload available</md-chip>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus                        from '../bus';

export default {
  props : {
    file : {
      type    : Object,
      default : () => {}
    }
  },

  data () {
    return {
      filename                 : '',
      justOpen                 : false,
      nativeFileSystemIsActive : 'showOpenFilePicker' in window,
      fileHandle               : null,
      fileIntervalRefresh      : null
    };
  },

  computed : mapGetters({
    current          : 'current',
    lastModifiedFile : 'lastModifiedFile',
    continueRefresh  : 'continueRefresh'
  }),

  methods : {
    ...mapActions({
      setLastModifiedFile : 'setLastModifiedFile',
      uploadTemplate      : 'uploadTemplate',
      setContinueRefresh  : 'setContinueRefresh',
      toastError          : 'toastError'
    }),

    newFile (event) {
      if (event.target.files.length > 0) {
        this.$emit('update:file', event.target.files[0]);
      }
    },

    /**
     * Upload a new `file` for a version
     */
    uploadNewTemplate (file) {
      var formData = new FormData();
      formData.append('file', file);

      this.uploadTemplate({
        idVersion : this.current.version.id,
        data      : formData
      }).then(() => {
        // Can refresh locally
        this.setContinueRefresh(true);
        this.setLastModifiedFile(file.lastModified);
        bus.$emit('refreshPreview');
      }, (msg) => {
        this.toastError(msg);
      });
    },

    getExportFormatList : function (filename) {
      var extension = filename.split('.').pop();
      if (extension && extension !== '') {
        this.$store.dispatch('getExportFormatList', extension);
      }
    },

    askFile : async function () {
      if (this.justOpen) {
        this.justOpen = false;
        return;
      }

      this.justOpen = true;

      const pickerOpts = {
        types: [
          {
            description: "Documents",
            accept: {
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
              "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
              "application/vnd.oasis.opendocument.text": [".odt"],
              "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
              "application/vnd.oasis.opendocument.presentation": [".odp"],
              "application/vnd.oasis.opendocument.graphics": [".odg"],
              "text/html": [".html"],
              "application/xml": [".xml"],
              "text/xml": [".xml"]
            },
          },
        ],
        // excludeAcceptAllOption: true,
        multiple: false 
      };

      try {
        [this.fileHandle] = await window.showOpenFilePicker(pickerOpts);
        this.file = await this.fileHandle.getFile();
        this.filename = this.file.name;
        this.setLastModifiedFile(this.file.lastModified);
        this.getExportFormatList(this.filename);
        this.$refs['file-input'].$el.blur();
        this.$emit('update:file', this.file);

        clearInterval(this.fileIntervalRefresh);
        this.fileIntervalRefresh = setInterval(async () => {
          if (this.fileHandle === null) {
            clearInterval(this.fileIntervalRefresh);
          }
          let newFile = await this.fileHandle.getFile().catch(err => {
            console.log("Upload | Watch local file error catched:", err.toString());
            bus.$emit('reset-live-reload-upload');
          })
          if (newFile != null &&
          this.lastModifiedFile !== -1 &&
          this.continueRefresh &&
          typeof this.current.report !== 'undefined' &&
          typeof this.current.report.id !== 'undefined') {
            if (this.lastModifiedFile !== newFile.lastModified) {
              this.setLastModifiedFile(newFile.lastModified);
              this.uploadNewTemplate(newFile);
            }
          }
        }, 1000);
      } catch (error) {
        console.log("Upload | Watch File Upload Global Error Catched:", error.toString());
        bus.$emit('reset-live-reload-upload');
      }
    },
    openArticleLiveReload () {
      const id = "how-to-enable-live-reload-1pd53ln"
      try {
        if (window.$crisp) {
          window.$crisp.push(["do", "helpdesk:article:open", ['en-us', id]])
        } else {
          window.open(`https://help.carbone.io/en-us/article/${id}`, "_blank");
        }
      } catch(err) {
        console.log("Helpdesk article not available: ", err.toString());
      }
    }
  },

  mounted () {
    bus.$on('resetInputFilename', () => {
      this.filename = '';
    })

    bus.$on('reset-live-reload-upload', () => {
      this.setContinueRefresh(false);
      this.fileHandle = null;
      clearInterval(this.fileIntervalRefresh);
    })
  },
  beforeDestroy () {
    bus.$off('reset-live-reload-upload');
    bus.$off('resetInputFilename');
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.choose::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: #673ab7;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}

.input-label {
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 600;
}
</style>
