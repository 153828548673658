import * as types from '../mutation-types';
import auth from '../../api/auth.js';

const state = {
  role    : null,
  email   : null,
  support : {
    isAvailable : false,
    value       : null
  }
};

const getters = {
  role    : state => state.role,
  email   : state => state.email,
  support : state => state.support
};

const actions = {
  loginCheck ({ commit }, data) {
    auth.loginCheck((res) => {
      if (res.success && res.data.role !== undefined) {
        commit(types.SET_ROLE, res.data.role);
        commit(types.SET_EMAIL, res.data.email);
      }
      data.cb(res);
    });
  },

  getUserSupport ({ commit }) {
    return new Promise((resolve, reject) => {
      auth.getSupport((result) => {
        if (result.success === false) {
          commit(types.SET_SUPPORT, false);
          return reject();
        }

        if (result.data.value.includes('No')) {
          // User does not have support
          commit(types.SET_SUPPORT, false);
          return resolve();
        }

        commit(types.SET_SUPPORT, true);
        commit(types.SET_SUPPORT_VALUE, result.data.value);
        return resolve();
      });
    });
  }
};

const mutations = {
  [types.SET_ROLE] (state, role) {
    state.role = role;
  },
  [types.SET_EMAIL] (state, email) {
    state.email = email;
  },
  [types.SET_SUPPORT] (state, support) {
    state.support.isAvailable = support;
  },
  [types.SET_SUPPORT_VALUE] (state, value) {
    state.support.value = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
