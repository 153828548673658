<template>
  <div id="sidenavMenu">
    <md-list>
      <item :options="ManageCategoriesItemOpts" @clickItem="$emit('clickItem')"></item>
      <item :options="ManageTagsItemOpts" @clickItem="$emit('clickItem')"></item>
    </md-list>
  </div>
</template>

<script>
import Item           from './SidenavMenuItem.vue';
import Hermes         from 'plugins/Hermes';
import { mapActions } from 'vuex';
import bus            from '../../../bus';
import config         from '../../../../config';

export default {
  components : {
    Item
  },
  computed : {
    isActive () {
      return (this.$route.path === this.options.link);
    },
    DashboardItemOpts () {
      return {
        libelle : 'Dashboard',
        icon    : 'dashboard',
        link    : '/'
      };
    },
    BackToCarboneAccountItemOpts () {
      return {
        libelle : 'Carbone Account',
        icon    : 'launch',
        link    : ''
      };
    },
    ManageCategoriesItemOpts () {
      return {
        libelle : 'Categories',
        icon    : 'folder',
        link    : '/categories'
      };
    },
    ManageTagsItemOpts () {
      return {
        libelle : 'Tags',
        icon    : 'label',
        link    : '/tags'
      };
    },
    LogoutItemOpts () {
      return {
        libelle : 'Logout',
        icon    : 'power_settings_new',
        link    : ''
      };
    }
  },
  methods: {
    ...mapActions({
      toast: 'toast',
      toastSuccess: 'toastSuccess',
      toastError: 'toastError'
    }),
    clickOnLink (name) {
      this.$emit('clickItem');
      this.$router.push({ name: name });
    }
  }
};
</script>
