import Vue             from 'vue';
import Vuex            from 'vuex';
import actions         from './actions';
import templateList    from './modules/templateList';
import template        from './modules/template';
import exportFormat    from './modules/exportFormat';
import jsonData        from './modules/jsonData';
import utils           from './modules/utils';
import settings        from './modules/settings';
import auth            from './modules/auth';
import carboneVersions from './modules/carboneVersions';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters : {},
  modules : {
    templateList,
    template,
    exportFormat,
    jsonData,
    utils,
    settings,
    auth,
    carboneVersions
  },
  settings : {}
});
