<template>
  <div class="vue-menu" :id="getMenuId">
    <slot></slot>
    <div ref="backdrop" class="vue-menu-backdrop" v-if="triggered" @click="untrigger"></div>
  </div>
</template>

<script>
import bus from '../bus';
import "./VueMenu.scss";

export default {
  name  : 'VueMenu',
  props : {
    openOnhover : {
      type    : Boolean,
      default : true
    }
  },
  computed : {
    getMenuId () {
      return `vueMenu${this._uid}`;
    },
    getToggle() {
      return this.triggered ? this.untrigger : this.trigger
    },
    getBound() {
      return this.$el.getBoundingClientRect()
    }
  },
  data () {
    return {
      triggerers: [],
      triggered : false,
      hasBackdrop: true
    };
  },
  methods : {
    emitEvent (event) {
      bus.$emit(event, this.getMenuId);
    },
    getChildrenComponents(name) {
      let children = []

      this.$children.map(item => {
        if (item.$options.name === name || name === '*')
          children.push(item)
      })

      return children
    },
    getChildComponent(name) {
      return this.getChildrenComponents(name)[0]
    },
    handleChildrenEvent(children, event, handler) {
      children.map(item => {
        item.$on(event, handler)
      })
    },
    emitChildrenEvent(children, event, data) {
      children.map(item => {
        item.$emit(event, data)
      })
    },
    toggleTrigger () {
      if (this.triggered) {
        this.untrigger();
      } else {
        this.trigger();
      }
    },
    trigger () {
      this.emitEvent('trigger')
      this.triggered = true;

      this.emitChildrenEvent(this.triggerers, 'expand')
      this.emitChildrenEvent(this.contents, 'expand')
      const content = this.getChildComponent('VueMenuContent')
      this.$options.content = content.$el

      if (this.hasBackdrop)
        document.body.appendChild(this.$options.content)

      content.x = this.getBound.x + 'px'
      content.top = this.getBound.y + this.getBound.height + 'px'

      this.$nextTick(() => {
        if (this.hasBackdrop)
          document.body.appendChild(this.$refs.backdrop)
        const { x, y, width, height } = content.$el.getBoundingClientRect()
        const offsetY = window.innerHeight - (y + 1.05 * height)
        const offsetX = window.innerWidth - (x + 1.05 * width)

        if (offsetY < 0)
          content.top = offsetY
        if (offsetX < 0)
          content.left = 'calc(-100% + 45px)'
      })
    },
    untrigger () {
      this.triggered = false;

      this.emitChildrenEvent(this.triggerers, 'retract')
      this.emitChildrenEvent(this.contents, 'retract')
      if (this.$refs.backdrop)
        this.$el.appendChild(this.$options.content)
    }
  },
  mounted () {
    this.triggerers = this.getChildrenComponents('VueMenuTrigger')
    this.contents = this.getChildrenComponents('VueMenuContent')

    this.handleChildrenEvent(this.triggerers, 'triggerClick', this.toggleTrigger)
    this.handleChildrenEvent(this.triggerers, 'triggerHover', () => this.emitEvent('triggerHover'))
    this.handleChildrenEvent(this.contents, 'triggerClick', this.toggleTrigger)
  }
};
</script>

<style lang="scss" src="./VueMenu.scss"></style>