<template id="categoriesList">
  <md-list>
    <md-list-item v-for="categ in templateListByCateg" :key="categ.id">
      <md-icon :style="categ.attr">folder</md-icon>
      <span>{{ categ.name }}</span>

      <md-list-expand>
              <md-list>
                <div @contextmenu.prevent="$refs.ctxMenu.open($event, report)"
                   v-for="report in categ.reports"
                   :key="report.id">
                    <md-list-item   class="md-inset"
                                    v-if="categ.reports.length != 0"
                                    v-on:click.prevent="displayDetailsReport(report, 'view')">
                        <div class="md-list-text-container">
                            <span>
                                <div class="pull-left">{{ report.originalFileName }}</div>
                                <md-chip class="md-primary md-chip-dense pull-left" style="margin-bottom: 5px" :style="tag.attr" v-for="tag in report.tags" :key="tag.id">{{ tag.name }}</md-chip>
                            </span>
                        </div>
                    </md-list-item>
                  </div>
                  <md-list-item class="md-inset" v-if="categ.reports.length == 0">
                      <span>Empty</span>
                  </md-list-item>
              </md-list>
          </md-list-expand>
    </md-list-item>
    <div @contextmenu.prevent="$refs.ctxMenu.open($event, report)"
       v-for="report in reports"
       :key="report.id">
          <md-list-item v-on:click.prevent="displayDetailsReport(report)">
              <md-icon>insert_drive_file</md-icon>
              <div class="md-list-text-container">
                  <span class="pull-left">
                      {{ report.originalFileName }}
                  </span>
                  <span>
                      <md-chip class="md-primary md-chip-dense pull-left" style="margin-bottom: 5px" :style="tag.attr" v-for="tag in report.tags" :key="tag.id">{{ tag.name }}</md-chip>
                  </span>
              </div>
          </md-list-item>
      </div>
    <context-menu ref="ctxMenu">
      <template slot-scope="child">
        <md-whiteframe md-elevation="3">
          <md-list class="md-dense">
            <md-list-item @click="displayDetailsReport(child.userData, 'view')">
              <md-icon class="md-18">remove_red_eye</md-icon> <span>Open Latest Version</span>
            </md-list-item>
            <md-list-item @click="displayDetailsReport(child.userData, 'edit')">
              <md-icon class="md-18">mode_edit</md-icon> <span>Edit Name / Category / Tags</span>
            </md-list-item>
            <md-list-item @click="checkDeleteReport(child.userData)" class="md-warn">
              <md-icon class="md-18">delete</md-icon> <span>Delete Template and All Versions</span>
            </md-list-item>
          </md-list>
        </md-whiteframe>
      </template>
    </context-menu>
  </md-list>
</template>

<script>
import ContextMenu    from './ContextMenu/ContextMenu.vue';
import bus            from './bus';
import { mapGetters } from 'vuex';

export default {
  props : {
    other : {
      type : Object
    },
    reports : {
      type     : Array,
      required : true
    }
  },
  computed : {
    ...mapGetters({
      templateListByCateg : 'templateListByCateg'
    })
  },
  components : {
    ContextMenu
  },
  data () {
    return {
      getOther () {
        return (this.other);
      }
    };
  },
  methods : {
    displayDetailsReport (report, state) {
      bus.$emit('displayDetailsReport', report, state);
    },
    checkDeleteReport (report) {
      bus.$emit('displayConfirmDelete', 'report', report);
    }
  }
};
</script>