/*
**  This mixin is used to replace the file input which will be listened
**  to live reload the preview
**  This is a tricky need, this is the Carbone Trick !
*/

export default {
  methods: {
    replaceCTInput(mdFile) {
      // Old input used for the Carbone Trick
      var _oldInput = document.getElementById('upload-template-input');
      var _oldInputParent = _oldInput.parentNode;
      // New input to be used for the Carbone Trick
      var _newInput = mdFile.$refs.fileInput;
      // We are cloning it to keep the original safe
      var _newInputClone = _newInput.cloneNode(false);

      // Assigning the good id to be recognized for the Carbone Trick
      _newInputClone.setAttribute('id', 'upload-template-input');

      // Replace the old Carbone Trick input
      _oldInputParent.removeChild(_oldInput);

      _oldInputParent.appendChild(_newInputClone);

      // And reset the original "new" input
      mdFile.resetFile();
      mdFile.filename = '';
    }
  }
}