export const GET_TEMPLATE_LIST_WITH_CATEG     = 'GET_TEMPLATE_LIST_WITH_CATEG';
export const GET_TEMPLATE_LIST_WITHOUT_CATEG  = 'GET_TEMPLATE_LIST_WITHOUT_CATEG';
export const GET_TEMPLATE_LIST_BY_CATEG       = 'GET_TEMPLATE_LIST_BY_CATEG';
export const GET_EXPORT_FORMAT_LIST           = 'GET_EXPORT_FORMAT_LIST';
export const SET_DATA_CARBONE                 = 'SET_DATA_CARBONE';
export const SET_COMPLEMENT_CARBONE           = 'SET_COMPLEMENT_CARBONE';
export const SET_ENUMS_CARBONE                = 'SET_ENUMS_CARBONE';
export const SET_TRANSLATIONS                 = 'SET_TRANSLATIONS';
export const SET_CURRENCIES                   = 'SET_CURRENCIES';
export const SET_CURRENCY_SOURCE              = 'SET_CURRENCY_SOURCE';
export const SET_CURRENCY_TARGET              = 'SET_CURRENCY_TARGET';
export const SET_ID_FILE_SERVER               = 'SET_ID_FILE_SERVER';
export const SET_EXT                          = 'SET_EXT';
export const SET_CATEGORY                     = 'SET_CATEGORY';
export const SET_VERSION                      = 'SET_VERSION';
export const SET_VERSION_TEMPLATE_ID          = 'SET_VERSION_TEMPLATE_ID';
export const SET_REPORT                       = 'SET_REPORT';
export const GET_CATEGORIES                   = 'GET_CATEGORIES';
export const SET_FILE                         = 'SET_FILE';
export const SET_CACHE_VERSION                = 'SET_CACHE_VERSION';
export const PUSH_CACHE_VERSION               = 'PUSH_CACHE_VERSION';
export const UPDATE_ID_ORDER                  = 'UPDATE_ID_ORDER';
export const SET_CAN_SAVE                     = 'SET_CAN_SAVE';
export const SET_ID_ORDER_TO_MAX              = 'SET_ID_ORDER_TO_MAX';
export const SET_ALL_VERSIONS                 = 'SET_ALL_VERSIONS';
export const INIT_ALL                         = 'INIT_ALL';
export const SET_SHARED                       = 'SET_SHARED';
export const SET_LIVE_RELOAD                  = 'SET_LIVE_RELOAD';
export const SET_ROLE                         = 'SET_ROLE';
export const SET_EMAIL                        = 'SET_EMAIL';
export const SET_FILENAME_TO_PREVIEW          = 'SET_FILENAME_TO_PREVIEW';
export const SET_FILE_INPUT_EXTENSION         = 'SET_FILE_INPUT_EXTENSION';
export const SET_TAGS                         = 'SET_TAGS';
export const PUSH_VERSION                     = 'PUSH_VERSION';
export const SET_LAST_MODIFIED_FILE           = 'SET_LAST_MODIFIED_FILE';
export const SET_CONTINU_REFRESH              = 'SET_CONTINU_REFRESH';
export const SET_SHARE_KEY                    = 'SET_SHARE_KEY';
export const UPDATE_ONE_VERSION_IN_LIST       = 'UPDATE_ONE_VERSION_IN_LIST';
export const SET_SUPPORT                      = 'SET_SUPPORT';
export const SET_SUPPORT_VALUE                = 'SET_SUPPORT_VALUE';
export const SET_CARBONE_VERSIONS             = 'SET_CARBONE_VERSIONS';
export const SET_CURRENT_CARBONE_VERSION      = 'SET_CURRENT_CARBONE_VERSION';
export const SET_LANG                         = 'SET°LANG';
export const SET_TIMEZONE                     = 'SET_TIMEZONE';
export const SET_NOCODE                       = 'SET_NOCODE';