<template>
  <form @submit.prevent="submitTag">
    <md-layout md-gutter="24">
      <md-layout md-flex="50">
        <md-input-container>
          <label>Tag name</label>
          <md-input v-model="tagName"></md-input>
        </md-input-container>
      </md-layout>

      <md-layout md-flex="50">
        <md-input-container :class="{ 'md-input-invalid': colorFormat }">
          <label>Tag color</label>
          <md-layout style="flex: 0">
            <div :style="colorPreview"></div>
          </md-layout>
          <md-layout>
            <md-input v-model="tagColor.hex"></md-input>
          </md-layout>
        </md-input-container>
      </md-layout>
    </md-layout>

    <compact :value="tagColor" @input="updateColor"></compact>

    <input type="submit" style="display: none" />

    <md-layout md-row :md-gutter="8">
      <md-layout>
        <md-button @click="close" class="md-block">
          Cancel
        </md-button>
      </md-layout>
      <md-layout>
        <md-button @click="submitTag" class="md-raised md-primary md-block">
          Validate
        </md-button>
      </md-layout>
    </md-layout>
  </form>
</template>

<script>
import { Compact } from 'vue-color';

export default {
  components : {
    Compact
  },
  props : {
    tag : {
      type    : Object,
      default : {},
      tagName : {
        type : String
      },
      tagColor : {
        type    : String,
        default : '000000'
      }
    }
  },
  data () {
    return {
      tagName  : this.tag.tagName,
      tagColor : {
        hex : this.tag.tagColor
      },
      colorPreview : {
        background   : '#' + this.cleanColor(this.tag.tagColor),
        height       : '1.5em',
        width        : '1.5em',
        borderRadius : '2pt',
        position     : 'relative',
        top          : '5px',
        marginRight  : '5px'
      },
      colorFormat : false
    };
  },
  watch : {
    'tagColor.hex' : function (newValue) {
      this.colorPreview.background = '#' + newValue;
      if (newValue.length > 6) {
        this.colorFormat = true;
      } else {
        this.colorFormat = false;
      }
    }
  },
  methods : {
    updateColor (val) {
      this.tagColor = val;
      this.tagColor.hex = this.cleanColor(val.hex);
      this.colorPreview.background = '#' + this.tagColor.hex;
    },
    submitTag () {
      this.tagColor.hex = this.cleanColor(this.tagColor.hex);
      this.$emit('submit', {
        tagName  : this.tagName,
        tagColor : this.tagColor
      });
    },
    close () {
      this.$emit('submit');
    },
    cleanColor (color) {
      return (color.charAt(0) === '#') ? color.substr(1) : color;
    }
  },
  mounted () {
    this.colorPreview.background = this.tag.tagColor;
  }
};
</script>