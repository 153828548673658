import utils from '../../api/utils';
import * as types from '../mutation-types';
//import Toast from '../../toast';

const state = {
  categories  : [],
  tags        : []
};

const getters = {
  categories  : state => state.categories,
  tags        : state => state.tags
};

const actions = {
  getCategories ({ commit }) {
    return new Promise((resolve, reject) => {
      utils.getCategories(list => {
        commit(types.GET_CATEGORIES, list);
        resolve();
      });
    });
  },
  getTags ({ commit }) {
    return new Promise((resolve, reject) => {
      utils.getTags((tags) => {
        commit(types.SET_TAGS, tags);
        resolve();
      });
    });
  },
  sendContact({ commit }, data) {
    return new Promise((resolve, reject) => {
      utils.sendContact(data, ({ message }) => {
        //Toast.addToast(message, 'success');
        resolve();
      });
    });
  }
};

const mutations = {
  [types.GET_CATEGORIES] (state, list) {
    state.categories = list;
  },
  [types.SET_TAGS] (state, tags) {
    state.tags = tags;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
