<template>
  <md-button class="md-icon-button" @click="toggle">
    <md-icon>menu</md-icon>
  </md-button>
</template>

<script>
import bus from 'src/Components/bus';

export default {
  methods : {
    toggle () {
      bus.$emit('dashboardSidenav');
    }
  }
};
</script>