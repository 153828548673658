import * as types from '../mutation-types';

const state = {
  dataForCarbone       : {},
  complementForCarbone : {},
  enumsForCarbone      : {},
  translations         : {}
};

const getters = {
  dataForCarbone       : state => state.dataForCarbone,
  complementForCarbone : state => state.complementForCarbone,
  enumsForCarbone      : state => state.enumsForCarbone,
  translations         : state => state.translations
};

const actions = {
  setDataForCarbone ({ commit }, data) {
    commit(types.SET_DATA_CARBONE, data);
  },
  setComplementForCarbone ({ commit }, data) {
    commit(types.SET_COMPLEMENT_CARBONE, data);
  },
  setEnumsForCarbone ({ commit }, data) {
    commit(types.SET_ENUMS_CARBONE, data);
  },
  setTranslations ({ commit }, data) {
    commit(types.SET_TRANSLATIONS, data);
  }
};

const mutations = {
  [types.SET_DATA_CARBONE] (state, data) {
    state.dataForCarbone = data;
  },
  [types.SET_COMPLEMENT_CARBONE] (state, data) {
    state.complementForCarbone = data;
  },
  [types.SET_ENUMS_CARBONE] (state, data) {
    state.enumsForCarbone = data;
  },
  [types.SET_TRANSLATIONS] (state, data) {
    state.translations = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};