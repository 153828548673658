module.exports  = {
  bmp   : { mime : 'image/bmp'                                                                 , charset : false  },
  csv   : { mime : 'text/csv'                                                                  , charset : 'UTF-8'},
  dbf   : { mime : 'application/vnd.dbf'                                                       , charset : false  },
  doc   : { mime : 'application/msword'                                                        , charset : false  },
  docx  : { mime : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'   , charset : false  },
  emf   : { mime : 'image/emf'                                                                 , charset : false  },
  eps   : { mime : 'application/postscript'                                                    , charset : false  },
  epub  : { mime : 'application/epub+zip'                                                      , charset : false  },
  gif   : { mime : 'image/gif'                                                                 , charset : false  },
  html  : { mime : 'text/html'                                                                 , charset : 'UTF-8'},
  idml  : { mime : 'application/vnd.adobe.indesign-idml-package'                               , charset : false  },
  jpeg  : { mime : 'image/jpeg'                                                                , charset : false  },
  jpg   : { mime : 'image/jpeg'                                                                , charset : false  },
  latex : { mime : 'application/x-latex'                                                       , charset : false  },
  odg   : { mime : 'application/vnd.oasis.opendocument.graphics'                               , charset : false  },
  odp   : { mime : 'application/vnd.oasis.opendocument.presentation'                           , charset : false  },
  ods   : { mime : 'application/vnd.oasis.opendocument.spreadsheet'                            , charset : false  },
  odt   : { mime : 'application/vnd.oasis.opendocument.text'                                   , charset : false  },
  otg   : { mime : 'application/vnd.oasis.opendocument.graphics-template'                      , charset : false  },
  otp   : { mime : 'application/vnd.oasis.opendocument.presentation-template'                  , charset : false  },
  ots   : { mime : 'application/vnd.oasis.opendocument.spreadsheet-template'                   , charset : false  },
  ott   : { mime : 'application/vnd.oasis.opendocument.text-template'                          , charset : false  },
  pbm   : { mime : 'image/x-portable-bitmap'                                                   , charset : false  },
  pct   : { mime : 'image/x-pict'                                                              , charset : false  },
  pdb   : { mime : 'application/vnd.palm'                                                      , charset : false  },
  pdf   : { mime : 'application/pdf'                                                           , charset : false  },
  pgm   : { mime : 'image/x-portable-graymap'                                                  , charset : false  },
  png   : { mime : 'image/png'                                                                 , charset : false  },
  pot   : { mime : 'application/vnd.ms-powerpoint'                                             , charset : false  },
  potm  : { mime : 'application/vnd.ms-powerpoint.template.macroenabled.12'                    , charset : false  },
  ppm   : { mime : 'image/x-portable-pixmap'                                                   , charset : false  },
  pps   : { mime : 'application/vnd.ms-powerpoint'                                             , charset : false  },
  ppt   : { mime : 'application/vnd.ms-powerpoint'                                             , charset : false  },
  pptx  : { mime : 'application/vnd.openxmlformats-officedocument.presentationml.presentation' , charset : false  },
  ras   : { mime : 'image/x-cmu-raster'                                                        , charset : false  },
  rtf   : { mime : 'application/rtf'                                                           , charset : false  },
  sda   : { mime : 'application/vnd.stardivision.draw'                                         , charset : false  },
  sdc   : { mime : 'application/vnd.stardivision.calc'                                         , charset : false  },
  sdd   : { mime : 'application/vnd.stardivision.impress'                                      , charset : false  },
  sdw   : { mime : 'application/vnd.stardivision.writer'                                       , charset : false  },
  stc   : { mime : 'application/vnd.sun.xml.calc.template'                                     , charset : false  },
  std   : { mime : 'application/vnd.sun.xml.draw.template'                                     , charset : false  },
  sti   : { mime : 'application/vnd.sun.xml.impress.template'                                  , charset : false  },
  stw   : { mime : 'application/vnd.sun.xml.writer.template'                                   , charset : false  },
  svg   : { mime : 'image/svg+xml'                                                             , charset : false  },
  swf   : { mime : 'application/x-shockwave-flash'                                             , charset : false  },
  sxc   : { mime : 'application/vnd.sun.xml.calc'                                              , charset : false  },
  sxd   : { mime : 'application/vnd.sun.xml.draw'                                              , charset : false  },
  sxi   : { mime : 'application/vnd.sun.xml.impress'                                           , charset : false  },
  sxw   : { mime : 'application/vnd.sun.xml.writer'                                            , charset : false  },
  text  : { mime : 'text/plain'                                                                , charset : 'UTF-8'},
  tiff  : { mime : 'image/tiff'                                                                , charset : false  },
  txt   : { mime : 'text/plain'                                                                , charset : 'UTF-8'},
  vor   : { mime : 'application/vnd.stardivision.writer'                                       , charset : false  },
  wmf   : { mime : 'image/wmf'                                                                 , charset : false  },
  xhtml : { mime : 'application/xhtml+xml'                                                     , charset : false  },
  xlam  : { mime : 'application/vnd.ms-excel.addin.macroenabled.12'                            , charset : false  },
  xls   : { mime : 'application/vnd.ms-excel'                                                  , charset : false  },
  xlsb  : { mime : 'application/vnd.ms-excel.sheet.binary.macroenabled.12'                     , charset : false  },
  xlsm  : { mime : 'application/vnd.ms-excel.sheet.macroenabled.12'                            , charset : false  },
  xlsx  : { mime : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'         , charset : false  },
  xlt   : { mime : 'application/vnd.ms-excel'                                                  , charset : false  },
  xltm  : { mime : 'application/vnd.ms-excel.template.macroenabled.12'                         , charset : false  },
  xml   : { mime : 'application/xml'                                                           , charset : false  },
  xpm   : { mime : 'image/x-xpixmap'                                                           , charset : false  }
};