<template>
  <div class="vue-menu-trigger" :class="{ expanded: expand }" @mouseover="hover" @click="emit">
    <slot></slot>
  </div>
</template>

<script>
import "./VueMenuTrigger.scss"

export default {
  name : 'VueMenuTrigger',
  data () {
    return {
      expand: false
    };
  },
  methods: {
    emit() {
      this.$emit('triggerClick', this)
    },
    hover() {
      this.$emit('triggerHover', this)
    }
  },
  mounted() {
    this.$on('expand', () => { this.expand = true })
    this.$on('retract', () => { this.expand = false })
  }
};
</script>

<style lang="scss" src="./VueMenuTrigger.scss"></style>