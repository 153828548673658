<template>
  <div id="modalEditReport">
    <md-dialog ref="editReport" @close="fileInputToSource" style="min-width: 400px !important;">
      <div v-if="canDisplay">
          <md-dialog-title style="display: flex;flex-direction: row;justify-content: space-between;">
            <span style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;max-width: 250px;display: block;font-weight: 600;">{{ open.report.originalFileName }}</span>
            <md-menu  v-if="state == 'view'"  md-direction="bottom left" style="margin-top:-6px">
              <md-button class="md-icon-button" md-menu-trigger style="margin:0"><md-icon>more_vert</md-icon></md-button>
              <md-menu-content>
                <md-menu-item  @click="reportsAction(4, $event);"><md-icon>edit_note</md-icon><span>Edit Name / Category / Tags</span></md-menu-item>
                <md-menu-item  @click="checkDeleteReport()" class="md-warn"><md-icon>delete_forever</md-icon> <span>Delete Template and All versions</span></md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-dialog-title>

          <md-dialog-content v-if="state == 'edit'">
            <md-input-container>
                  <label>Template Name</label>
                  <md-input name="newReportName" id="newReportName" :value="open.report.originalFileName.split('.')[0]" autofocus></md-input>
              </md-input-container>
              <strong>Category</strong>
              <v-select :options="categories" label="name" placeholder="Select a category" v-model="open.category"></v-select>
              <br />
              <strong>Tags</strong>
              <v-select multiple :options="listTags" label="name" placeholder="Select tags" v-model="open.tags"></v-select>
          </md-dialog-content>

          <md-dialog-actions v-if="state == 'edit'">
              <md-button @click="reportsAction(5, $event)">back</md-button>
              <md-button @click="reportsAction(2, $event); closeDialog()" class="md-primary">update</md-button>
          </md-dialog-actions>

          <md-dialog-content v-if="state == 'view'">
            <span  v-if="open.category !== null && typeof open.category != 'undefined' && typeof open.category.name != 'undefined' && open.category.name !== 'No category'">
              <strong style="font-size: 15px;font-weight: 600;">Category: </strong><br><md-chip class="md-chip-dense" > {{ open.category.name }} </md-chip>
              <br/><br/>
            </span>
            <span v-if="open.tags && open.tags.length > 0">
              <strong style="font-size: 15px;font-weight: 600;">Tags:</strong><br>
              <md-chip class="md-primary md-chip-dense" style="margin-bottom: 5px" :style="tag.attr" v-for="tag in open.tags" :key="tag.id">
                {{ tag.name }}
              </md-chip>
              <br/><br/>
            </span>
            <div>
                <span style="font-size: 15px;font-weight: 600;">Versions</span>
                <br />
                <versions-list :openedReport="open"
                        @version-selected="closeDialog(); closeSidenav()"></versions-list>
            </div>
          </md-dialog-content>

          <md-dialog-actions v-if="state == 'view'" style="padding: 0px 24px 8px 24px !important">
            <md-button @click="closeDialog()" class="md-pimary" style="margin-right:auto">Close</md-button>
            <md-button @click.prevent="openLast" class="md-pimary">Open Latest Version</md-button>
          </md-dialog-actions>
      </div>
    </md-dialog>
    <confirm-delete></confirm-delete>
  </div>
</template>

<script>
import Hermes                     from 'plugins/Hermes';
import bus                        from './bus';
import ConfirmDelete              from './ConfirmDelete.vue';
import CarboneTrick               from 'mixins/CarboneTrick';
import VersionsList               from 'src/Components/report/version/VersionsList.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  mixins : [CarboneTrick],
  data () {
    return {
      open       : {},
      state      : 'view',
      canDisplay : false,
      toto       : []
    };
  },
  components : {
    ConfirmDelete,
    VersionsList
  },
  computed : {
    ...mapGetters({
      current    : 'current',
      old        : 'old',
      categories : 'categories',
      listTags   : 'tags'
    })
  },
  methods : {
    ...mapActions({
      getFullReport     : 'getFullReport',
      getVersions       : 'getVersions',
      getReportVersions : 'getReportVersions',
      getCategories     : 'getCategories',
      getTags           : 'getTags',
      setInCurrent      : 'setInCurrent',
      deleteReportApi   : 'deleteReport',
      updateReportApi   : 'updateReport',
      toastInfo         : 'toastInfo',
      toastSuccess      : 'toastSuccess',
      toastError        : 'toastError',
      setAllVersions    : 'setAllVersions'
    }),
    closeSidenav () {
      bus.$emit('menu', 'close');
    },
    customLabelVersion : function (option) {
      return `${option.versionNumber}`;
    },
    customLabelByName : function (option) {
      return `${option.name}`;
    },

    /**
     * Open this dialog
     */
    openDialog () {
      if (this.$refs.editReport) {
        this.$refs.editReport.open();
      }
    },

    /**
     * Close this dialog
     */
    closeDialog () {
      if (this.$refs.editReport) {
        this.canDisplay = false;
        this.$refs.editReport.close();
      }
    },

    /**
     * Move Carbone tricks to root
     */
    fileInputToSource () {
      this.canDisplay = false;
      //this.replaceCTInput(this.$refs['carbone-trick-input']);
    },

    /**
     * Open the last version of a report
     */
    openLast () {
      var data = this.open;
      data.idVersion = this.open.versions[0].id;
      this.setInCurrent(data).then(() => {
        bus.$emit('setJson', 'data');
        bus.$emit('refreshPreview');
        this.closeDialog();
        this.closeSidenav();
      });
    },

    /**
     * Execute differentes actions depends on which button is clicked
     */
    reportsAction : function (idBtn, e) {
      if (typeof e !== 'undefined') {
        e.preventDefault();
      }
      if (idBtn === 2) {
        this.updateReport(this.open.report.id);
        this.state = 'view';
        setTimeout(() => {
          this.refreshTemplateList();
        }, 300);
      } else if (idBtn === 4) {
        this.state = 'edit';
        return;
      } else if (idBtn === 5) {
        this.state = 'view';
      }
    },

    /**
     * Function call when the update report form is sbmitted
     * Check input and call the request
     */
    updateReport : function (id) {
      var name = document.getElementById('newReportName').value;

      if (name.trim() === '')
        return this.toastError('Wrong report\'s name');

      var categ = (this.open.category.id === -1) ? null : this.open.category;
      var data = {
        name       : name,
        idCategory : categ.id,
        tags       : this.open.tags
      };

      this.updateReportApi({
        idReport : id,
        data     : data
      }).then((msg) => {
        this.toastSuccess(msg);
      }, (msg) => {
        this.toastError(msg);
      })
    },

    /**
     * Delete a report
     */
    deleteReport : function (report) {
      if (typeof report === 'undefined') {
        report = this.open.report;
      }

      this.deleteReportApi(report.id).then((msg) => {
        // Success, update everything
        this.refreshTemplateList();
        if (this.current.report.id === report.id) {
          this.$store.dispatch('initAll');
          bus.$emit('refreshPreview');
          bus.$emit('resetPreview');
          bus.$emit('resetData');
        }
        this.toastSuccess(msg);
        this.closeDialog();

      }, (msg) => {
        this.toastError(msg);
      })
    },
    checkDeleteReport () {
      bus.$emit('displayConfirmDelete', 'report', this.open.report);
    },
    refreshTemplateList () {
      bus.$emit('refreshList', 1);
    }
  },
  beforeDestroy () {
    bus.$off('displayDetailsReport');
    bus.$off('deleteReport');
    bus.$off('fetch-all-versions');
  },
  mounted () {
    /**
     * open the modal with the selected report
     * @param report : report selected in sidenav
     * @param state : open modal with view mode or edit mode
     */
    bus.$on('displayDetailsReport', (report, state) => {
      this.getCategories().then(() => {
        this.categories.unshift("No category");
      });
      this.getTags();
      this.getFullReport(report.id).then((data) => {
        /** this.open.report.id */
        this.open = data;

        if (this.open.category === undefined || this.open.category.id === undefined) {
          this.open.category = {
            name : "No category"
          };
        }

        for (var i = 0 ; i < this.open.tags.length ; i++) {
          this.open.tags[i].attr = 'background-color: #' + this.open.tags[i].color + ';';
        }

        // Set the good view (view | edit)
        if (state !== undefined) {
          this.state = state;
        } else {
          this.state = 'view';
        }

        this.canDisplay = true;
        this.openDialog();
      }, () => {
        // Error occured while getting report
        this.toastError('Can\'t get this report info');
      });
    });

    bus.$on('deleteReport', (report) => {
      this.deleteReport(report);
    });

    bus.$on('fetch-all-versions', (idReport) => {
      // Check if we get all versions for current report
      this.getReportVersions(idReport).then((versions) => {
        this.open.versions = versions;
      }, (message) => {
        // Error occured while getting report
        this.toastError(message);
      });
    });
  }
};
</script>
