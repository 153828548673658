<template>
  <md-layout md-column id="dashboard">
    <toolbar></toolbar>
    <md-layout class="nowrap">
      <md-layout md-flex-small="100" md-column id="wrapper">
        <div class="content-container">
          <router-view></router-view>
        </div>
      </md-layout>

      <md-layout md-hide-medium-and-up style="flex: 0">
        <sidenav @shiftWrapper="shiftWrapper" @unshiftWrapper="unshiftWrapper" :class="{ persistent: isPersistent }"></sidenav>
      </md-layout>
      <md-layout md-hide-small class="static-sidenav" style="flex: 0">
        <sidenav class="md-active" @shiftWrapper="shiftWrapper" @unshiftWrapper="unshiftWrapper" :is-fixed="false"></sidenav>
      </md-layout>
    </md-layout>
  </md-layout>
</template>

<script>
import Toolbar                    from './toolbar/Toolbar.vue';
import Sidenav                    from './sidenav/Sidenav.vue';
import bus                        from 'src/Components/bus';
import { mapGetters, mapActions } from 'vuex';

export default {
  components : {
    Toolbar,
    Sidenav
  },
  computed : {
    isPersistent () {
      return window.innerWidth > 1280;
    }
  },
  data() {
    return {
      shift: false
    }
  },
  methods : {
    isNotSmall () {
      return false;
      return (window.innerWidth > 1280) ? (true) : (false);
    },
    shiftWrapper () {
      this.shift = true;
    },
    unshiftWrapper () {
      this.shift = false;
    }
  },
  mounted () {
    
    if (this.isPersistent) {
      bus.$emit('dashboardSidenav', 'open');
    }
  }
};
</script>

<style lang="scss">
#wrapper {
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 0;
  margin: 0 auto;
  flex: 1;
  align-items: center;
  padding: 1em;
}

.static-sidenav {
  position: relative;

  .md-sidenav-content {
    position: static !important;
  }
}

.md-sidenav-content {
  height: 100%;
}
</style>

<style lang="scss" scoped>
.content-container {
  max-width: 1000pt;
  width: 100%;
}

.nowrap {
  flex-wrap: nowrap;
}
</style>
