<template>
  <div
    v-show="isVisible"
    class="lil-context-menu"
    :style="style"
    tabindex="-1"
    @blur="close"
    @click="close"
    @contextmenu.capture.prevent>
    <slot :user-data="userData"></slot>
  </div>
</template>

<script>
export default {
  name : 'lil-context-menu',
  data () {
    return {
      x           : null,
      y           : null,
      userData    : null,
      ctxVisible  : false,
      isListening : false
    };
  },
  computed : {
    style () {
      return this.isVisible ? {
        top  : this.y - document.body.scrollTop + 'px',
        left : this.x + 'px'
      } : {};
    },
    isVisible () {
      return this.x !== null && this.y !== null;
    }
  },
  methods : {
    open (evt, userData) {
      this.originalParentNode = this.$el.parentNode;
      this.$el.parentNode.removeChild(this.$el);
      document.body.appendChild(this.$el);
      this.x = evt.pageX || evt.clientX;
      this.y = evt.pageY || evt.clientY;
      this.userData = userData;
      this.ctxVisible = true;
      this.start();
    },
    close () {
      this.originalParentNode.appendChild(this.$el);
      this.x = null;
      this.y = null;
      this.ctxVisible = false;
      this.userData = null;
      this.stop();
    },
    start (cb) {
      window.addEventListener('click', this._onclick, true);
      window.addEventListener('keyup', this._onescape, true);
      this.isListening = true;
      if (typeof cb === 'function') {
        cb();
      }
    },

    stop (cb) {
      window.removeEventListener('click', this._onclick, true);
      window.removeEventListener('keyup', this._onescape, true);
      this.isListening = false;
      if (typeof cb === 'function') {
        cb();
      }
    },
    _onclick (e) {
      e.preventDefault();
      let isOpen = !!this.ctxVisible;
      let outsideClick = isOpen && !this.$el.contains(e.target);

      if (outsideClick) {
        this.ctxVisible = false;
        this.close();
      }
    },
    _onescape (e) {
      if (e.keyCode === 27) {
        this._onclick(e);
      }
    }
  },
  watch : {
    ctxVisible (newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.stop(() => {});
      }
    }
  }
};
</script>

<style>
.li-context-menu {
  position: relative;
    color: #333;
    cursor: pointer;
    list-style: none;
    margin: 0;
    white-space: nowrap;
}
.li-context-menu:hover {
  background-color: #eee;
  border-color: #eee;
}
.lil-context-menu {
  position: fixed;
  background-color: #fff;
  z-index: 250;
  transition: opacity 0.218s;
    background: #fff;
    cursor: default;
    font-size: 13px;
    margin: 0;
    outline: none;
}
.lil-context-menu:focus {
  outline: none;
}
</style>
