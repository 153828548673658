<template>
  <md-dialog ref="ModalUploadTemplate">
    <md-dialog-title>Upload template to studio</md-dialog-title>

    <form ref="form">
      <md-dialog-content>
        <file-handler :file.sync="file"></file-handler>
        <br/>
        <p>📝 <b>Info</b>: The template ID is a SHA256 hash of the document content. If the content changes, the template ID changes as well.<br>
          Uploading a new template file will create a new unique template ID for the current version.<br>
          If your existing template has been used in production to generate documents through the Carbone Cloud API for the last seven days,<br>
          the current template ID will be automatically retained; otherwise, it will be deleted and replaced by the new template.<br>
          You can also create a new report version (menu Version -> New version from...) to permanently keep the existing template ID.
        </p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="close">Cancel</md-button>
        <md-button class="md-primary" @click="submit">Ok</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>
import { mapGetters, mapActions }   from 'vuex';
import bus                          from 'src/Components/bus';
import FileHandler                  from '../../FileHandler/FileHandler';

export default {
  props : {
    report : {
      type    : Object,
      default : null
    }
  },
  data () {
    return {
      lastModifiedFile         : null,
      newReport                : {},
      file                     : null,
      nativeFileSystemIsActive : 'chooseFileSystemEntries' in window
    };
  },
  components : {
    FileHandler
  },
  computed : {
    ...mapGetters({
      current : 'current'
    }),
  },
  methods : {
    ...mapActions({
      uploadTemplate     : 'uploadTemplate',
      setContinueRefresh : 'setContinueRefresh',
      toastInfo          : 'toastInfo',
      toastSuccess       : 'toastSuccess',
      toastError         : 'toastError'
    }),

    /**
     * Submit the form and add a cache version
     */
    submit () {
      if (this.file === null) {
        return this.toastError('You must upload a file');
      }

      var formData = new FormData();
      formData.append('name', this.current.report.originalFileName);
      formData.append('file', this.file);

      this.uploadTemplate({
        idVersion : this.current.version.id,
        data      : formData
      }).then(() => {
        this.$refs.form.reset();
        // Can refresh locally
        this.setContinueRefresh(true);

        bus.$emit('refreshPreview');
        this.close();
      }, (msg) => {
        this.toastError(msg);
      });
    },

    /**
     * Open this modal
     */
    open () {
      bus.$emit('resetInputFilename');
      this.$refs.ModalUploadTemplate.open();

      // Stop refresh with local template wile modal is open
      this.setContinueRefresh(false);
    },

    /**
     * Close this modal
     */
    close () {
      this.$refs.ModalUploadTemplate.close();
    }
  },
  mounted () {
    bus.$on('ModalUploadTemplate', (action) => {
      if (action === undefined) {
        action = 'open';
      }
      this[action]();
    });
  },
  beforeDestroy () {
    bus.$off('ModalUploadTemplate'); 
  }
};
</script>
