<template>
  <div>
    <md-button class="md-icon-button md-list-action" @click="$emit('submit-edit')">
      <md-icon>done</md-icon>
    </md-button>
  </div>
</template>

<script>
export default {
  props : {
    version : {
      type     : Object,
      required : true
    }
  }
};
</script>
