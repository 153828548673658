<template>
  <md-list-item @click="goToLink" :class="getClass">
    <md-icon>{{ options.icon }}</md-icon> <span>{{ options.libelle }}</span>

    <md-list-expand v-if="typeof getList != 'undefined'">
      <md-list>
        <md-list-item class="md-inset" v-for="item in getList" :key="item.route" @click="goToKey(item.title)">{{ item.title }}</md-list-item>
      </md-list>
    </md-list-expand>
  </md-list-item>
</template>

<script>
export default {
  props : {
    options : {
      type     : Object,
      required : true,
      icon     : {
        type     : String,
        required : true
      },
      libelle : {
        type     : String,
        required : true
      },
      link : {
        type     : String,
        required : true
      },
      list : {
        type    : [Array, Object],
        default : {}
      }
    }
  },
  methods : {
    goToLink () {
      if (typeof this.getList === 'undefined') {
        this.$emit('clickItem');
      }
      if (typeof this.options.link !== 'undefined') {
        this.$router.push(this.options.link);
      }
    },
    goToKey (key) {
      this.$emit('clickItem');
      var url = `/documentation/${key}`;
      this.$router.push(url);
    }
  },
  computed : {
    getClass () {
      return (this.$route.path === this.options.link) ? ('active') : ('');
    },
    getList () {
      return this.options.list;
    }
  },
  mounted () {
  }
};
</script>