<template>
  <div>
    <view-action 	v-if="stateIs(VIEWING)"
            :version="version"
            :openedReport="openedReport"
            @state-change="changeState"
            @version-selected="$emit('version-selected')"
            @toggle-privacy="$emit('toggle-privacy')"></view-action>
    <edit-action v-if="stateIs(EDITTING)"
      :version="version"
      @state-change="changeState"
      @submit-edit="$emit('submit-edit')"></edit-action>
  </div>
</template>

<script>
import ViewAction from './VersionsListActionView.vue';
import EditAction from './VersionsListActionEdit.vue';

export default {
  props : {
    state : {
      type     : String,
      required : true
    },
    version : {
      type     : Object,
      required : true
    },
    openedReport : {
      type     : Object,
      required : true
    }
  },
  components : {
    ViewAction,
    EditAction
  },
  computed : {
  },
  data () {
    return {
      VIEWING  : 'VIEW',
      EDITTING : 'EDIT'
    };
  },
  methods : {
    stateIs (state) {
      return (this.state === state);
    },
    changeState (state) {
      this.$emit('state-change', state);
    }
  }
};
</script>
