<template>
  <md-layout :md-column="true">
  <modal-confirm-delete-category></modal-confirm-delete-category>
    <md-layout md-tag="md-card" :md-column="true">
      <md-list v-if="cats.length > 0">
        <transition-group name="fade">
          <div v-for="cat in cats" :key="cat.id">
            <cat-item :cat="cat" @update-cats="getCategories" />
          </div>
        </transition-group>
      </md-list>
      <md-card-content v-else>
        <span>No categories</span>
      </md-card-content>
    </md-layout>
  </md-layout>
</template>

<script>
import bus                        from 'src/Components/bus';
import Hermes                     from 'plugins/Hermes';
import CatItem                    from './Category.vue';
import modalConfirmDeleteCategory from 'src/Components/ModalConfirmDeleteCategory.vue';

export default {
  data () {
    return {
      cats    : [],
      current : null,
    };
  },
  components : {
    CatItem,
    modalConfirmDeleteCategory
  },
  methods : {
    // Get all the categories of a user
    getCategories : function () {
      Hermes.run({
        route : 'category.categories'
      }, (data) => {
        var res = data.data;
        for (var i = 0 ; i < res.length ; i++) {
          res[i].attr = 'background-color: #' + res[i].color + ';';
        }
        this.cats = res;
      });
    }
  },
  created : function () {
    this.getCategories();
  },
  mounted () {
    bus.$on('updateCatsList', this.getCategories);
  },
  beforeDestroy () {
    bus.$off('updateCatsList', this.getCategories);
  }
};
</script>
