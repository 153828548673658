<template>
  <div class="grid" :class="getClass" :style="`flex-basis: ${basis}; justify-content: ${justify}; align-items: ${align}; flex-grow: ${grow}`">
    <slot></slot>
  </div>
</template>

<script>
import styles from "./grid.scss"

export default {
  name  : 'grid',
  props : {
    horizontalGutter : {
      type    : Number,
      default : 0
    },
    verticalGutter : {
      type    : Number,
      default : 0
    },
    basis : {
      type    : String,
      default : 'auto'
    },
    justify : {
      type    : String,
      default : 'flex-start'
    },
    align : {
      type    : String,
      default : 'flex-start'
    },
    grow : {
      type    : String,
      default : '0'
    }
  },
  data () {
    return {
      container : false,
      child     : false,
      column     : false,
      row         : false
    };
  },
  computed : {
    getClass : function () {
      return {
        'grid-container'            : this.container,
        'grid-child'                : this.child,
        'grid-column'               : this.column,
        'grid-row'                  : this.row,
        'grid-horizontal-gutter-10' : (this.horizontalGutter === 10) ? (true) : (false),
        'grid-horizontal-gutter-20' : (this.horizontalGutter === 20) ? (true) : (false),
        'grid-vertical-gutter-10'   : (this.verticalGutter === 10) ? (true) : (false),
        'grid-vertical-gutter-20'   : (this.verticalGutter === 20) ? (true) : (false),
      };
    }
  },
  methods : {
    getProp (name) {
      if (typeof this.$el.attributes.getNamedItem(name) !== 'undefined')
      {return this.$el.attributes.getNamedItem(name);}
      return false;
    },
    checkProp () {
      this.container = this.getProp('container');
      this.child     = this.getProp('child');
      this.column    = this.getProp('column');
      this.row       = this.getProp('row');
      if (!this.column && !this.row) {
        this.row = true;
      }
      if (!this.container && !this.child) {
        this.container = true;
      }
    }
  },
  mounted () {
    this.checkProp();
  }
};
</script>

<style lang="scss" src="./grid.scss"></style>