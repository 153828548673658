<template>
    <md-sidenav class="md-right" ref="rightSidenav">
    <md-toolbar class="md-dense">
      <div class="md-toolbar-container">
        <h3 class="md-title" style="font-weight: 600;">Versions</h3>
        <span style="flex: 1"></span>
        <md-button class="md-icon-button md-dense" @click="closeRightSidenav"><md-icon>close</md-icon></md-button>
      </div>
      
    </md-toolbar>

    
    <md-list style="padding:0">
        <md-list-item>
            <md-icon>add</md-icon>
            <span>New Version</span>
            <md-list-expand>
            <md-list>
                <md-list-item @click="newVersionFromLocalFile"><md-icon>drive_folder_upload</md-icon><span>from local file</span></md-list-item>
                <md-list-item @click="newVersionFromCurrentVersion"><md-icon>cloud_upload</md-icon><span>from the opened version</span></md-list-item>
            </md-list>
            </md-list-expand>
        </md-list-item>
    </md-list>
    <md-divider></md-divider>
    <md-list class="md-triple-line" style="padding-top:0px">
        
        
        <md-list-item v-for="(version, index) in current.versions" :key="version.id" style="position:relative">
            <span v-if="index !== 0" id="bar-top" style="position:absolute;width: 6px;height: 37px;background-color: black;top: 0px;left: 25px;"></span>
            <span v-if="index !== current.versions.length - 1" id="bar-bottom" style="position:absolute;width: 6px;height: 35px;background-color: black;top: 53px;left: 25px;"></span>
            <md-button class="md-icon-button md-list-action" style="margin-left:-8px" @click="openVersion(version)">
                <md-icon v-if="isActive(version) === false" >radio_button_unchecked</md-icon>
                <md-icon v-else >radio_button_checked</md-icon>
            </md-button>

            <div class="md-list-text-container">
                <b>V{{ version.versionNumber }}</b>
                <span v-if="version.description ">{{ version.description }}</span>
                <p><b>ID:</b> {{ version.filename }}</p>
            </div>

            <md-menu md-size="4" md-direction="bottom left">
      <md-button class="md-icon-button md-list-action" md-menu-trigger>
        <md-icon>more_vert</md-icon>
      </md-button>

      <md-menu-content>
        <md-menu-item @click="openVersion(version)" v-if="isActive(version) === false">
          <md-icon>edit_document</md-icon>
          <span>Open Version</span>
        </md-menu-item>
         
        <md-menu-item @click="openModalEditVersion">
          <md-icon>edit_note</md-icon>
          <span>Edit X.X.X Tag / Description</span>
        </md-menu-item>
        <md-menu-item @click="copyTemplateId">
          <md-icon>content_copy</md-icon>
          <span>Copy Template ID</span>
        </md-menu-item>
        <md-menu-item class="md-warn" @click="checkDeleteVersion(version)">
          <md-icon>delete_forever</md-icon>
          <span>Delete Version</span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
            
            <md-divider></md-divider>
        </md-list-item>
    </md-list>
  </md-sidenav>
</template>

<script>

import bus                         from './bus';
import { mapGetters, mapActions }  from 'vuex';

export default {
  components : {
    
  },
  methods : {
    ...mapActions({
      setVersionInCurrent : 'setVersionInCurrent'
    }),
    closeRightSidenav () {
      this.$refs.rightSidenav.close();
    },
    isActive (version) {
      return version.id === this.current.version.id;
    },
    openVersion (version) {
      if (this.isActive(version) === true) {
        return ;
      }
      this.setVersionInCurrent(version.id).then(() => {
        bus.$emit('setJson', 'data');
        bus.$emit('refreshPreview');
      });
    },
    openModalEditVersion () {
      bus.$emit('ModalEditVersion');
    },
    newVersionFromLocalFile () {
      bus.$emit('newVersionFromLocalFile');
    },
    newVersionFromCurrentVersion () {
      bus.$emit('newVersionFromCurrentVersion');
    },
    copyTemplateId () {
      bus.$emit('copyTemplateId');
    },
    checkDeleteVersion (version) {
      bus.$emit('displayConfirmDelete', 'version', {
        idReport  : this.current.report.id,
        idVersion : version.id
      });
    }
  },
  computed : {
    ...mapGetters({
      current : 'current'
    })
  },
  mounted () {
    bus.$on('menu-versions', (status) => {
      if (typeof this.$refs.rightSidenav !== 'undefined') {
        if (status === 'open') {
          this.$refs.rightSidenav.open();
        } else if (status === 'close') {
          this.$refs.rightSidenav.close();
        } else {
          this.$refs.rightSidenav.toggle();
        }
      }
    });
  },
  beforeDestroy () {
    bus.$off('menu-versions');
  }
};
</script>