import Hermes from 'plugins/Hermes';

export default {
  loginCheck : function (cb) {
    fetch('/login/check', {
      method      : 'GET',
      credentials : 'same-origin',
      headers     : {
        Accept : 'application/json'
      }
    }).then((response) => {
      response.text().then((data) => {
        cb(JSON.parse(data));
      });
    });
  },

  /**
   * Return the support value of the current user subscription
   * @param {Function} callback
   */
  getSupport : function (callback) {
    Hermes.run({
      route  : 'user.getSubscriptionValue',
      params : {
        subscriptionName : 'studio',
        keyValue         : 'support'
      }
    }, (data) => {
      return callback(data);
    });
  }
};
