<template>
  <div id="displayByCateg">
    <md-list>
            <md-list-item v-for="categ in list" :key="categ.id">
                <md-icon>folder</md-icon>
                <span>{{ categ.name }}</span>

                <md-list-expand>
                    <md-list>
                        <md-list-item class="md-inset" v-for="report in categ.reports" :key="report.id">
                            <span>
                                {{ report.originalfilename }}
                            </span>
                        </md-list-item>
                    </md-list>
                </md-list-expand>
            </md-list-item>
        </md-list>
  </div>
</template>

<script>
export default {
  props : ['list']
};
</script>
