<template>
  <md-list class="md-double-line md-dense">
        <md-list-item v-for="report in getList()" :key="report.id" v-on:click="displayDetailsReport(report)">
            <md-icon>insert_drive_file</md-icon>
            <div class="md-list-text-container">
                <span class="pull-left">
                    {{ report.originalFileName }}
                </span>
                <span>
                    <div class="pull-left">{{ report.category.name }}</div>
                    <md-chip class="md-primary md-chip-dense pull-left" style="margin-bottom: 5px" :style="tag.attr" v-for="tag in report.tags" :key="tag.id">{{ tag.name }}</md-chip>
                </span>
            </div>
        </md-list-item>
    </md-list>
</template>

<script>
import bus from './bus';

export default {
  props : {
    reports : {
      type     : Array,
      required : true
    }
  },
  data () {
    return {
      getList () {
        return (this.reports);
      }
    };
  },
  methods : {
    displayDetailsReport (report) {
      bus.$emit('displayDetailsReport', report);
    }
  }
};
</script>