<template>
  <div id="loading-status" class="md-whiteframe-1dp" v-show="isActive">
    <md-progress md-indeterminate></md-progress>
  </div>
</template>

<script>
import Hermes from 'plugins/Hermes';

export default {
  data () {
    return {
      isActive : Hermes.pendingRequests
    };
  },
  created () {
    Hermes.addRequestListener((count) => {
      this.isActive = count;
    });
  }
};
</script>

<style lang="scss" scoped>
#loading-status {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
  background: white;
}
</style>