<template>
  <div>
    <md-menu md-size="4" md-direction="bottom right">
      <md-button class="md-icon-button md-list-action" md-menu-trigger>
        <md-icon>more_vert</md-icon>
      </md-button>

      <md-menu-content>
        <md-menu-item @click="selectInStudio(version)">
          <md-icon>edit_document</md-icon>
          <span>Open Version</span>
        </md-menu-item>
        <md-menu-item @click="changeState(EDITTING)">
          <md-icon>edit_note</md-icon>
          <span>Edit X.X.X Tag / Description</span>
        </md-menu-item>
        <md-menu-item v-clipboard:copy="version.filename"
                      v-clipboard:success="onCopySuccess"
                      v-clipboard:error="onCopyError">
          <md-icon>content_copy</md-icon>
          <span>Copy Template ID</span>
        </md-menu-item>
        <md-menu-item @click="checkDeleteVersion(version)" class="md-warn">
          <md-icon>delete_forever</md-icon>
          <span>Delete Version</span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
    <version-info :ref="getIdReportInfo"></version-info>
  </div>
</template>

<script>
import bus         from 'src/Components/bus';
import VersionInfo from './VersionInfo.vue';
import { mapActions } from 'vuex';

export default {
  props : {
    version : {
      type     : Object,
      required : true
    },
    openedReport : {
      type     : Object,
      required : true
    }
  },
  data () {
    return {
      message  : 'test',
      EDITTING : 'EDIT'
    };
  },
  components : {
    VersionInfo
  },
  computed : {
    isShared () {
      return this.version.shareKey !== null;
    },
    getId () {
      return this.version.id + 'VersionList';
    },
    getIdReportInfo () {
      return `ReportInfo${this.getId}`;
    }
  },
  methods : {
    ...mapActions({
      setInCurrent : 'setInCurrent',
      toastSuccess : 'toastSuccess',
      toastError   : 'toastError',
    }),
    selectInStudio (version) {
      var data = this.openedReport;
      data.idVersion = this.version.id;
      this.setInCurrent(data).then(() => {
        bus.$emit('setJson', 'data');
        bus.$emit('refreshPreview');
        this.$emit('version-selected');
        this.$store.dispatch('setShared', false);
      });
    },
    onCopySuccess () {
      this.setEnableCheckDeletion(true);
      this.toastSuccess('Template ID copied')
    },
    onCopyError () {
      this.toastError('Cannot copy template ID')
    },
    checkDeleteVersion (version) {
      bus.$emit('displayConfirmDelete', 'version', {
        idReport  : this.openedReport.report.id,
        idVersion : version.id
      });
    },
    changeState (state) {
      this.$emit('state-change', state);
    },
    togglePrivacy () {
      this.$emit('toggle-privacy');
    }
  }
};
</script>
