<template>
  <md-layout :md-column="true">
    <md-layout md-tag="md-card" :md-column="true">
      <md-list v-if="tags.length > 0">
        <transition-group name="fade">
          <div v-for="tag in tags" :key="tag.id">
            <tag-item :tag="tag" @update-tags="getTags" />
          </div>
        </transition-group>
      </md-list>
      <md-card-content v-else>
        <span>No tag</span>
      </md-card-content>
    </md-layout>
  </md-layout>
</template>

<script>
import bus     from 'src/Components/bus';
import Hermes  from 'plugins/Hermes';
import TagItem from './Tag.vue';

export default {
  data () {
    return {
      tags    : [],
      current : null,
    };
  },
  components : {
    TagItem
  },
  methods : {
    getTags : function () {
      Hermes.run({
        route : 'tag.getTags'
      }, (data) => {
        var res = data.data;
        for (var i = 0 ; i < res.length ; i++) {
          res[i].attr = 'background-color: #' + res[i].color + ';';
        }
        this.tags = res;
      });
    }
  },
  created : function () {
    this.getTags();
  },
  mounted () {
    bus.$on('updateTagsList', this.getTags);
  },
  beforeDestroy () {
    bus.$off('updateTagsList', this.getTags);
  }
};
</script>