<template>
  <div class="carbone-toasts-container" ref="container">
    <transition-group name="carbone-toasts-list" tag="div">
      <template v-for="(toast, key) in toasts">
        <toast :key="key" class="carbone-toast-item" v-if="toast.alive" :options="toast" @destroy="destroyToast"></toast>
      </template>
    </transition-group>
  </div>
</template>

<script>
import Toast from './Toast.vue';

export default {
  components: {
    Toast
  },
  data() {
    return {
      uid: 0,
      toasts: []
    }
  },
  methods: {
    addToast(options) {
      options.$id = this.uid++;
      options.alive = true;
      this.toasts.push(Object.assign({}, options));
    },
    destroyToast(id) {
      this.toasts = this.toasts.map(elem => (elem.$id === id) ? (elem.alive = false) : (elem));
      this.toasts.filter(elem => elem.alive);
    }
  }
}
</script>

<style lang="scss" scoped>
.carbone-toasts-container {
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  padding: 10px;
  overflow: hidden;
  z-index: 100;
}
.carbone-toast-item {
  transition: all .3s ease;
}
.carbone-toasts-list-enter, .carbone-toasts-list-leave-to {
  opacity: 0;
  display: inline-block;
  margin-left: -30px;
}
</style>
