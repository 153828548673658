<template>
  <div>
    <loading></loading>
    <grid column container id="app">
      <router-view class="grid"></router-view>
    </grid>
  </div>
</template>

<script>

import Loading    from 'src/Components/loading/LoadingStatus.vue';

export default {
  data () {
    return {};
  },
  components : {
    Loading
  }
};
</script>

<style lang="scss">
body {
  overflow-x: hidden;
}
</style>
