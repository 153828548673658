<template>
  <div>
    <div>
      <md-button class="md-raised md-dense refreshButton" v-if="activeLiveReloading === false" @click="refreshPreview">Refresh preview</md-button>
    </div>
    <md-switch v-model="liveReload" @change="activeLiveReloading"></md-switch>
  </div>
</template>

<script>
import { mapGetters, mapMutations }     from 'vuex';

export default {
  computed : {
    ...mapGetters([
      'current',
      'liveReload'
    ]),
    ...mapMutations({
      setLiveReload : 'SET_LIVE_RELOAD'
    })
  },
  mounted () {
  },
  methods : {
    activeLiveReloading : function (val) {
      this.$store.commit('SET_LIVE_RELOAD', val);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
