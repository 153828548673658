import list from '../../api/exportFormatList';
import * as types from '../mutation-types';

const state = {
  exportFormatList : {}
};

const getters = {
  exportFormatList : state => state.exportFormatList,
};

const actions = {
  getExportFormatList ({ commit }, extension) {
    if (extension && extension !== undefined && extension !== '') {
      list.getExportFormatList(extension, list => {
        commit(types.GET_EXPORT_FORMAT_LIST, { list });
      });
    }
  },
};

const mutations = {
  [types.GET_EXPORT_FORMAT_LIST] (state, { list }) {
    state.exportFormatList = list;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};