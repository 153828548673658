<template>
  <div id="modalConfirmDeleteCategory">
    <md-dialog ref="confirmDeleteCategory">
      <md-dialog-title>{{ categ.name }} is not empty</md-dialog-title>

      <form id="formConfirmDeleteCategory">
        <md-dialog-content v-if="categ != null">
          <span>We cannot remove your category because it is not empty :</span>
          <md-list>
            <md-list-item>
              <span>See reports list of the category</span>

              <md-list-expand>
                <md-list>
                  <md-list-item class="md-inset" v-for="report in categ.reports" :key="report.id">
                    {{ report.originalFileName }}
                  </md-list-item>
                </md-list>
              </md-list-expand>
            </md-list-item>
          </md-list>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="closeDialog('confirmDeleteCategory')">Cancel</md-button>
          <md-button @click.prevent="deleteAll()" type="submit" class="md-primary">Delete reports with category</md-button>
          <md-button @click.prevent="onlyCategory()" type="submit" class="md-primary">Delete the category alone</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </div>
</template>

<script>
import bus   from 'src/Components/bus';

export default {
  data () {
    return {
      categ : {}
    };
  },
  methods : {
    openDialog (ref) {
      var elem = this.$refs[ref];
      elem.open();
    },
    closeDialog (ref) {
      var elem = this.$refs[ref];
      elem.close();
    },
    deleteAll () {
      bus.$emit('deleteCategoryWithReports', this.categ);
    },
    onlyCategory () {
      bus.$emit('deleteOnlyCategory', this.categ);
    }
  },
  mounted () {
    bus.$on('displayConfirmDeleteCategory', (categ) => {
      this.categ = categ;
      this.openDialog('confirmDeleteCategory');
    });
    bus.$on('closeConfirmDeleteCategory', () => {
      this.closeDialog('confirmDeleteCategory');
    });
  },
  beforeDestroy () {
    bus.$off('displayConfirmDeleteCategory');
    bus.$off('closeConfirmDeleteCategory');
  }
};
</script>
