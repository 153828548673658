<template>
  <md-list-item>
    <md-avatar :style="cat.attr" class="md-avatar-icon"><md-icon>folder</md-icon></md-avatar>
    <span>{{ cat.name }}</span>
    <span>
      <md-menu md-size="4" ref="addCatMenu">
        <md-button class="md-icon-button" md-menu-trigger><md-icon>edit</md-icon></md-button>
        <md-menu-content>
          <div class="md-menu-container">
            <cat-editor @submit="editCat" :cat="{ catName: cat.name, catColor: cat.color }" />
          </div>
        </md-menu-content>
      </md-menu>
      <md-button class="md-icon-button" v-if="cat.deletable == true" v-on:click="checkDeleteCategorie(cat)"><md-icon>delete</md-icon></md-button>
    </span>
  </md-list-item>
</template>

<script>
import CatEditor  from '../CatEditor.vue';
import Hermes     from 'plugins/Hermes';
import { mapGetters, mapActions } from 'vuex';
import bus        from 'src/Components/bus';

export default {
  props : {
    cat : {
      type     : Object,
      required : true
    }
  },
  components : {
    CatEditor
  },
  data () {
    return {

    };
  },
  computed : mapGetters({
    templateListByCateg : 'templateListByCateg',
    current             : 'current'
  }),
  methods : {
    ...mapActions({
      toast                  : 'toast',
      toastError             : 'toastError',
      getTemplateListByCateg : 'getTemplateListByCateg'
    }),
    // Update a category
    editCat (cat) {
      if (!cat) {
        return this.$refs.addCatMenu.close();
      }
      var data = {
        name  : cat.catName,
        color : cat.catColor.hex
      };
      Hermes.run({
        route  : 'category.updateCategory',
        params : {
          id : this.cat.id
        },
        data : data
      }, () => {
        bus.$emit('updateCatsList');
        this.$store.dispatch('getTemplateListByCateg', {});
        this.$store.dispatch('getTemplateListWithCateg', {});
        this.$refs.addCatMenu.close();
      }, (data) => {
        this.toastError(data.message);
      });
    },
    // Check if a category is empty.
    // If ti's not empty, open a modal with the list of report in the category
    // Two choices: delete only the category and move the reports without category or delete the category and the reports
    checkDeleteCategorie : function (categ) {
      this.checkCategoryIsEmpty(categ, (empty) => {
        if (empty === true) {
          this.deleteCategorie(categ);
        } else {
          for (var i = 0 ; i < this.templateListByCateg.length ; i++) {
            if (this.templateListByCateg[i].id === categ.id) {
              bus.$emit('displayConfirmDeleteCategory', this.templateListByCateg[i]);
            }
          }
        }
      });
    },
    // Return if a category is empty
    checkCategoryIsEmpty (categ, cb) {
      for (var i = 0 ; i < this.templateListByCateg.length ; i++) {
        if (this.templateListByCateg[i].id === categ.id) {
          if (this.templateListByCateg[i].reports.length > 0) {
            return cb(false);
          } else {
            return cb(true);
          }
        }
      }
      return cb(true);
    },
    // Check if the report open in studio is in the category which has been deleted
    checkCurrentReportIsInCategoryToDelete (categ) {
      for (var i = 0 ; i < this.templateListByCateg.length ; i++) {
        if (this.templateListByCateg[i].id === categ.id) {
          for (var j = 0 ; j < this.templateListByCateg[i].reports.length ; j++) {
            var cur = this.templateListByCateg[i].reports[j];
            if (this.current.report.id === cur.id) {
              return true;
            }
          }
        }
      }
      return false;
    },
    // Delete a category
    deleteCategorie : function (categ, choice) {
      var body = {
        choice : choice
      };
      Hermes.run({
        route  : 'category.deleteCategory',
        params : {
          id : categ.id
        },
        data : body
      }, () => {
        bus.$emit('updateCatsList');
        bus.$emit('closeConfirmDeleteCategory');
        if (this.checkCurrentReportIsInCategoryToDelete(categ) === true && choice === 'deleteAll') {
          this.$store.dispatch('initAll');
          bus.$emit('resetPreview');
          bus.$emit('resetData');
        } else if (this.checkCurrentReportIsInCategoryToDelete(categ) === true && choice === 'onlyCategory') {
          this.$store.dispatch('setCategory', {});
        }
        bus.$emit('refreshList');
      }, (error) => {
        this.toastError(error.message);
      });
    }
  },
  mounted () {
    // Update category list
    this.getTemplateListByCateg();

    bus.$on('deleteOnlyCategory', (categ) => {
      this.deleteCategorie(categ, 'onlyCategory');
    });
    bus.$on('deleteCategoryWithReports', (categ) => {
      this.deleteCategorie(categ, 'deleteAll');
    });
  },
  beforeDestroy () {
    bus.$off('deleteOnlyCategory');
    bus.$off('deleteCategoryWithReports');
  }
};
</script>
