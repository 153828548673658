const moment = require('moment');

module.exports = {
  smartDate (date) {
    var localDate = convertInLocalFormat(date);
    var hours = Math.floor(Math.abs(new Date() - localDate) / 36e5);
    if (hours <= 24)
    {return (moment(localDate).fromNow());}
    else
    {return (moment(localDate).format('LLL'));}
  },
  billDate (date) {
    var localDate = convertInLocalFormat(date);
    return (moment(localDate).format('LLL'));
  },
  getFormatDate (date) {
    var localDate = convertInLocalFormat(date);
    return (moment(localDate).format('D/MM/YYYY'));
  },
  init (lang) {
    if (lang === 'fr') {
      initFr();
    }
    if (lang === 'en') {
      initEn();
    }
  }
};

function convertInLocalFormat (date) {
  var offset = new Date().getTimezoneOffset();
  date = new Date(date);
  date.setMinutes(date.getMinutes() - offset);
  return (date);
}

function initEn () {
  moment.locale('en', {
    months         : 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort    : 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    weekdays       : 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    weekdaysShort  : 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    weekdaysMin    : 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
    longDateFormat : {
      LT   : 'HH:mm',
      LTS  : 'HH:mm:ss',
      L    : 'DD/MM/YYYY',
      LL   : 'D MMMM YYYY',
      LLL  : '[on] D MMMM YYYY HH:mm',
      LLLL : 'dddd, D MMMM YYYY HH:mm'
    },
    calendar : {
      sameDay  : '[Today at] LT',
      nextDay  : '[Tomorrow at] LT',
      nextWeek : 'dddd [at] LT',
      lastDay  : '[Yesterday at] LT',
      lastWeek : '[Last] dddd [at] LT',
      sameElse : 'L'
    },
    relativeTime : {
      future : 'in %s',
      past   : '%s ago',
      s      : 'a few seconds',
      m      : 'a minute',
      mm     : '%d minutes',
      h      : 'an hour',
      hh     : '%d hours',
      d      : 'a day',
      dd     : '%d days',
      M      : 'a month',
      MM     : '%d months',
      y      : 'a year',
      yy     : '%d years'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(st|nd|rd|th)/,

    ordinal : function (number) {
      var b = number % 10;
      var output = (~~(number % 100 / 10) === 1) ? 'th' : (b === 1) ? 'st' : (b === 2) ? 'nd' : (b === 3) ? 'rd' : 'th';
      return number + output;
    },
    week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4 // The week that contains Jan 4th is the first week of the year.
    }
  });
}

function initFr () {
  moment.locale('fr', {
    months             : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort        : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact   : true,
    weekdays           : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort      : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin        : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat     : {
      LT   : 'HH:mm',
      LTS  : 'HH:mm:ss',
      L    : 'DD/MM/YYYY',
      LL   : 'D MMMM YYYY',
      LLL  : '[le] D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
      sameDay  : '[Aujourd’hui à] LT',
      nextDay  : '[Demain à] LT',
      nextWeek : 'dddd [à] LT',
      lastDay  : '[Hier à] LT',
      lastWeek : 'dddd [dernier à] LT',
      sameElse : 'L'
    },
    relativeTime : {
      future : 'dans %s',
      past   : 'il y a %s',
      s      : 'quelques secondes',
      m      : 'une minute',
      mm     : '%d minutes',
      h      : 'une heure',
      hh     : '%d heures',
      d      : 'un jour',
      dd     : '%d jours',
      M      : 'un mois',
      MM     : '%d mois',
      y      : 'un an',
      yy     : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,

    ordinal : function (number) {
      return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,

    isPM : function (input) {
      return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours) {
      return hours < 12 ? 'PD' : 'MD';
    },
    week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4 // The week that contains Jan 4th is the first week of the year.
    }
  });
}