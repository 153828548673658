<template>
  <div>
    <md-dialog-confirm
      md-title="Please confirm"
      md-content-html="Are you sure?"
      md-ok-text="Yes"
      md-cancel-text="No"
      @close="onClose"
      ref="confirmDelete">
    </md-dialog-confirm>
  </div>
</template>

<script>
import bus from './bus.js';

export default {
  data () {
    return {
      type  : null,
      value : null
    };
  },
  methods : {
    onClose (type) {
      if (type === 'ok') {
        var label = null;
        if (this.type === 'report') {
          label = 'deleteReport';
        }
        if (this.type === 'version') {
          label = 'deleteVersion';
        }
        if (label !== null) {
          bus.$emit(label, this.value);
        }
      }
    },
    openDialog (type, value) {
      this.type = type;
      this.value = value;
      this.$refs.confirmDelete.open();
    }
  },
  mounted () {
    bus.$on('displayConfirmDelete', this.openDialog);
  },
  beforeDestroy () {
    bus.$off('displayConfirmDelete', this.openDialog);
  }
};
</script>
