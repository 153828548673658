import Hermes from 'plugins/Hermes';

export default {
  /**
   * Return the list of available carbone versions
   * @param {Function} cb : callback
   */
  getCarboneVersion (cb) {
    Hermes.run({
      route : 'carboneVersions.getList'
    }, (data) => {
      cb(null, data);
    }, (error) => {
      return cb(error);
    });
  },
};
