<template>
  <div class="vue-submenu" @mouseenter="triggerContent" @mouseleave="untriggerContent">
    <slot></slot>
  </div>
</template>

<script>
import VueMenu from '../VueMenu/VueMenu.vue'
import "./VueSubmenu.scss"

export default {
  name: 'VueSubmenu',
  extends: VueMenu,
  data() {
    return {
      content: null,
      hasBackdrop: false
    }
  },
  methods: {
    triggerContent() {
      this.trigger()
      this.$nextTick(() => {
        this.content.x = '100%'
      })
    },
    untriggerContent() {
      this.content.left = 0
      this.content.top = 0
      this.untrigger()
    }
  },
  mounted() {
    this.content = this.getChildComponent('VueMenuContent')
  }
}
</script>

<style lang="scss" src="./VueSubmenu.scss"></style>