import Hermes from 'plugins/Hermes';

export default {
  // It search all categories of a user and return it
  getCategories : function (cb) {
    Hermes.run({
      route : 'category.categories'
    }, (data) => {
      var res = data.data;
      cb(res);
    });
  },

  // It search all tags of a user and return it
  getTags : function (cb) {
    Hermes.run({
      route : 'tag.getTags'
    }, (data) => {
      var res = data.data;
      cb(res);
    });
  },

  sendContact: function (data, cb) {
    Hermes.run({
      route: 'contact.send',
      data
    }, (data) => {
      cb(data)
    });
  },
};
