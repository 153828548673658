export default [
  { code : 'utc' },
  { code : 'america/chicago' },
  { code : 'america/detroit' },
  { code : 'america/guayaquil' },
  { code : 'america/guyana' },
  { code : 'america/los_angeles' },
  { code : 'america/martinique' },
  { code : 'america/mexico_city' },
  { code : 'america/montreal' },
  { code : 'america/new_york' },
  { code : 'america/sao_paulo' },
  { code : 'america/toronto' },
  { code : 'america/vancouver' },
  { code : 'asia/bangkok' },
  { code : 'asia/dubai' },
  { code : 'asia/qatar' },
  { code : 'asia/seoul' },
  { code : 'asia/shanghai' },
  { code : 'asia/singapore' },
  { code : 'asia/tel_aviv' },
  { code : 'asia/tokyo' },
  { code : 'australia/melbourne' },
  { code : 'australia/sydney' },
  { code : 'etc/utc' },
  { code : 'europe/amsterdam' },
  { code : 'europe/athens' },
  { code : 'europe/belfast' },
  { code : 'europe/belgrade' },
  { code : 'europe/berlin' },
  { code : 'europe/brussels' },
  { code : 'europe/budapest' },
  { code : 'europe/busingen' },
  { code : 'europe/copenhagen' },
  { code : 'europe/dublin' },
  { code : 'europe/kiev' },
  { code : 'europe/london' },
  { code : 'europe/luxembourg' },
  { code : 'europe/madrid' },
  { code : 'europe/monaco' },
  { code : 'europe/moscow' },
  { code : 'europe/oslo' },
  { code : 'europe/paris' },
  { code : 'europe/prague' },
  { code : 'europe/rome' },
  { code : 'indian/reunion' },
  { code : 'pacific/tahiti' },
  { code : 'us/alaska' },
  { code : 'us/aleutian' },
  { code : 'us/arizona' },
  { code : 'us/central' },
  { code : 'us/eastern' },
  { code : 'us/hawaii' },
  { code : 'us/michigan' },
  { code : 'us/mountain' },
  { code : 'us/pacific' },
  
];
