export default [
  { code : 'en-us', name : 'English' },
  { code : 'en-gb', name : 'English' },
  { code : 'en-ca', name : 'English' },
  { code : 'en-ie', name : 'English' },
  { code : 'en-za', name : 'English' },
  { code : 'es-es', name : 'Spanish' },
  { code : 'es-mx', name : 'Spanish' },
  { code : 'fr-fr', name : 'French' },
  { code : 'fr-be', name : 'French' },
  { code : 'de-de', name : 'German' },
  { code : 'de-at', name : 'German' },
  { code : 'nl-nl', name : 'Dutch' },
  { code : 'pt-br', name : 'Portuguese' },
  { code : 'it-it', name : 'Italian' },
  { code : 'sv-se', name : 'Swedish' },
  { code : 'sv-fi', name : 'Swedish' },
  { code : 'pl', name : 'Polish' },
  { code : 'et', name : 'Estonian' },
  { code : 'da', name : 'Danish' },
  { code : 'fi', name : 'Finnish' },
  { code : 'bg', name : 'Bulgaria' },
  { code : 'uk', name : 'Ukrainian' },
  { code : 'ja', name : 'Japanese' },
  { code : 'zh-cn', name : 'Chinese' },
  { code : 'zh-hk', name : 'Chinese' },
  { code : 'zh-tw', name : 'Chinese' },
  { code : 'ko', name : 'Korean' },
  { code : 'km', name : 'Khmer' },
  { code : 'th', name : 'Thai' },
  { code : 'si', name : 'Sinhala' },
  { code : 'id', name : 'Indonesian' },
  { code : 'ru', name : 'Russian' },
  { code : 'tr', name : 'Turkish' },
  { code : 'ar-eg', name : 'Arabic' },
  { code : 'ar-sa', name : 'Arabic' },
  { code : 'ar-ae', name : 'Arabic' },
  { code : 'ar-ma', name : 'Arabic' },
  { code : 'kk', name : 'Kazakh' },
  { code : 'he', name : 'Hebrew' },
];
