<template>
  <md-list class="md-dense md-double-line">
    <div v-for="(version, index) in openedReport.versions" :key="version.id">
      <item-content :openedReport="openedReport"
              :version="version"
              :first="index === 0"
              :last="index === openedReport.versions.length - 1"
              :state="state"
              @state-change="setState"
              @version-selected="$emit('version-selected')"></item-content>
    </div>
  </md-list>
</template>

<script>
import bus                        from 'src/Components/bus';
import { mapGetters, mapActions } from 'vuex';
import ItemContent                from './VersionsListItem.vue';
import Hermes                     from 'plugins/Hermes';

export default {
  props : {
    openedReport : {
      type     : Object,
      required : true
    }
  },
  components : {
    ItemContent
  },
  data () {
    return {
      state    : 'VIEW',
      VIEWING  : 'VIEW',
      EDITTING : 'EDIT'
    };
  },
  computed : {
    ...mapGetters({
      current : 'current'
    })
  },
  methods : {
    ...mapActions({
      toastInfo    : 'toastInfo',
      toastSuccess : 'toastSuccess',
      toastError   : 'toastError'
    }),
    setState (state) {
      this.state = state;
    },
    stateIs (state) {
      return (this.state === state);
    },
    submitEdit () {
      this.setState('VIEW');
    },
  },
  beforeDestroy () {

  },
  mounted () {
    
  }
};
</script>
