import Hermes from 'plugins/Hermes';

export default {
  // It search the list of all extension available for export
  getExportFormatList (extension, cb) {
    Hermes.run({
      route : 'report.getExtension'
    }, (data) => {
      var fileFormatList = data;
      for (var fileFormat in fileFormatList) {
        if (fileFormatList[fileFormat][extension] ) {
          return cb(fileFormatList[fileFormat]);
        }
      }
    });
  }
};