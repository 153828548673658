<template>
  <form @submit.prevent="submitCat">
    <md-layout md-gutter="24">
      <md-layout md-flex="50">
        <md-input-container>
          <label>Category name</label>
          <md-input v-model="catName"></md-input>
        </md-input-container>
      </md-layout>

      <md-layout md-flex="50">
        <md-input-container :class="{ 'md-input-invalid': colorFormat }">
          <label>Category color</label>
          <md-layout style="flex: 0">
            <span :style="colorPreview"></span>
          </md-layout>
          <md-layout>
            <md-input v-model="catColor.hex"></md-input>
          </md-layout>
        </md-input-container>
      </md-layout>
    </md-layout>

    <compact :value="catColor" @input="updateColor"></compact>

    <input type="submit" style="display: none" />

    <md-layout md-row :md-gutter="8">
      <md-layout>
        <md-button @click="close" class="md-block">
          Cancel
        </md-button>
      </md-layout>
      <md-layout>
        <md-button @click="submitCat" class="md-raised md-primary md-block">
          Validate
        </md-button>
      </md-layout>
    </md-layout>
  </form>
</template>

<script>
import { Compact } from 'vue-color';

export default {
  components : {
    Compact
  },
  props : {
    cat : {
      type    : Object,
      default : {},
      catName : {
        type : String
      },
      catColor : {
        type    : String,
        default : '000000'
      }
    }
  },
  data () {
    return {
      catName  : this.cat.catName,
      catColor : {
        hex : this.cat.catColor
      },
      colorPreview : {
        background   : '#' + this.cleanColor(this.cat.catColor),
        height       : '1.5em',
        width        : '1.5em',
        borderRadius : '2pt',
        position     : 'relative',
        top          : '5px',
        marginRight  : '5px'
      },
      colorFormat : false
    };
  },
  watch : {
    'catColor.hex' : function (newValue) {
      this.colorPreview.background = '#' + newValue;
      this.colorFormat = false;
      if (newValue.length > 6) {
        this.colorFormat = true;
      }
    }
  },
  methods : {
    // Update the color value when update it in the component
    updateColor (val) {
      this.catColor = val;
      this.catColor.hex = this.cleanColor(val.hex);
      this.colorPreview.background = '#' + this.catColor.hex;
    },
    // Submit a new category to add
    submitCat () {
      this.catColor.hex = this.cleanColor(this.catColor.hex);
      this.$emit('submit', {
        catName  : this.catName,
        catColor : this.catColor
      });
    },
    close () {
      this.$emit('submit');
    },
    // Remove the # in front of the color if there is one
    cleanColor (color) {
      return (color.charAt(0) === '#') ? color.substr(1) : color;
    }
  },
  mounted () {
    this.colorPreview.background = this.cat.catColor;
  }
};
</script>