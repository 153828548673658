<template id="reportsList">
  <div>
    <md-list class="md-double-line">
      <div @contextmenu.prevent="$refs.ctxMenu.open($event, report)"
         v-for="report in getList()"
         :key="report.id">
            <md-list-item v-on:click.prevent="displayDetailsReport(report)">
                <md-icon>description</md-icon>
                <div class="md-list-text-container">
                    <span class="pull-left">
                        {{ report.originalFileName }}
                    </span>
                    <span>
                        <div class="pull-left" v-if="report.category !== undefined">{{ report.category.name }}</div>
                        <md-chip class="md-primary md-chip-dense pull-left" style="margin-bottom: 5px" :style="tag.attr" v-for="tag in report.tags" :key="tag.id">{{ tag.name }}</md-chip>
                    </span>
                </div>
            </md-list-item>
        </div>
        <md-list-item v-if="getList().length == 0">
          No templates
        </md-list-item>
        <context-menu ref="ctxMenu">
        <template slot-scope="child">
          <md-whiteframe md-elevation="3">
            <md-list class="md-dense">
              <md-list-item @click="displayDetailsReport(child.userData, 'view')">
                <md-icon class="md-18">edit_document</md-icon> <span>Open Latest Version</span>
              </md-list-item>
              <md-list-item @click="displayDetailsReport(child.userData, 'edit')">
                <md-icon class="md-18">edit_note</md-icon> <span>Edit Name / Category / Tags</span>
              </md-list-item>
              <md-list-item @click="checkDeleteReport(child.userData)" class="md-warn">
                <md-icon class="md-18">delete_forever</md-icon> <span>Delete Template And All Versions</span>
              </md-list-item>
            </md-list>
          </md-whiteframe>
        </template>
      </context-menu>
      </md-list>
  </div>
</template>

<script>
import ContextMenu from './ContextMenu/ContextMenu.vue';
import bus         from './bus';

export default {
  props : {
    reports : {
      type     : Array,
      required : true
    }
  },
  components : {
    ContextMenu
  },
  data () {
    return {
      getList () {
        return (this.reports);
      }
    };
  },
  methods : {
    openMenu () {
      this.$refs[0].open;
    },
    displayDetailsReport (report, state) {
      bus.$emit('displayDetailsReport', report, state);
    },
    checkDeleteReport (report) {
      bus.$emit('displayConfirmDelete', 'report', report);
    }
  }
};
</script>