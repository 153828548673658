import VueMenuComponent from './components/VueMenu/VueMenu.vue';
import VueMenuItem       from './components/VueMenuItem/VueMenuItem.vue';
import VueMenuTrigger    from './components/VueMenuTrigger/VueMenuTrigger.vue';
import VueMenuContent    from './components/VueMenuContent/VueMenuContent.vue';
import VueSubmenu       from './components/VueSubmenu/VueSubmenu.vue';
import VueMenuGroup    from './components/VueMenuGroup/VueMenuGroup.vue';

const VueMenu = {
   install (Vue) {
      Vue.component(VueMenuComponent.name, VueMenuComponent);
      Vue.component(VueMenuItem.name, VueMenuItem);
      Vue.component(VueMenuTrigger.name, VueMenuTrigger);
      Vue.component(VueMenuContent.name, VueMenuContent);
      Vue.component(VueSubmenu.name, VueSubmenu);
      Vue.component(VueMenuGroup.name, VueMenuGroup);
   }
};

export default VueMenu;